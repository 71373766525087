import { LocationItem } from "@app/admin/shipment-entry/interface";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { Const } from '@const/Const';
import { ResponseAdminOrderDetail } from "@wearewarp/types/rest-api/admin";

export class AddRemoveStopsService {

  public static getListLocation_FromModelOrder(modelOrder: ResponseAdminOrderDetail) {
    let val: Array<LocationItem> = [];
    switch (modelOrder?.shipmentEntryMode) {
      case Const.ShipmentEntryMode.single:
        val = AddRemoveStopsService.singleLocationBuilder_FromModelOrder(modelOrder);
        break;
      case Const.ShipmentEntryMode.multiDrop:
        val = AddRemoveStopsService.multiDropLocationBuilder_FromModelOrder(modelOrder);
        break;
      case Const.ShipmentEntryMode.multiPick:
        val = AddRemoveStopsService.multiPickLocationBuilder_FromModelOrder(modelOrder);
        break;
      case Const.ShipmentEntryMode.multiPickDrop:
        val = AddRemoveStopsService.multiPickDropLocationBuilder_FromModelOrder(modelOrder);
        break;
    }
    return val;
  }

  // convert dữ liệu từ model sang mảng location truyền vào drag and drop
  // single thì ko quan tâm đến sortedTaskIds
  private static singleLocationBuilder_FromModelOrder(modelOrder: ResponseAdminOrderDetail) {
    let val: Array<LocationItem> = []; 
    const shipment = modelOrder?.metadata?.shipments?.[0];
    if (!shipment) return val;
    const deliveryInfos = shipment.deliveryInfos || [];
    const pickInfo = deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
    const dropInfo = deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
    const itemIds : any[] = shipment.itemIds ?? [];
    shipment.items = modelOrder.items?.filter(it => itemIds.includes(it.id));
    if (pickInfo) {
      const serviceOptions = (pickInfo?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt))
      const info: LocationItem  = {
        ...pickInfo,
        shipmentId: shipment.id,
        shipmentWarpId: `S-${shipment.warpId}`,
        serviceOptions,
        items: shipment.items,
      }
      val.push(info);
    }
    if (dropInfo) {
      const serviceOptions = (dropInfo?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt))
      const info: LocationItem  = {
        ...dropInfo,
        shipmentId: shipment.id,
        shipmentWarpId: `S-${shipment.warpId}`,
        serviceOptions,
        items: shipment.items,
      }
      val.push(info);
    }
    return val;
  }

  // khi thêm pickup, dropoff thì phải update lại mảng location truyền vào drag and drop
  public static getListDeliveryInfo_FromStopChange_MultiPick_Or_MultiDrop(currentLocations: LocationItem[] = [], originLocation: LocationItem[] = [], stopsChangeData) {
    let val: Array<LocationItem> = [];
    const deletedIds: any[] = stopsChangeData.deleted ?? [];
    const newStops = stopsChangeData.new ?? [];
    const newStopIds = newStops.map(it => it.id);
    const originLocationIds = originLocation.map(item => item['id']);
    // ko thuộc new, ko thuộc origin, mà thuộc current => cũng phải xóa
    val = currentLocations.filter(it => !deletedIds.includes(it['id']))
      ?.filter(it => !(!newStopIds.includes(it['id']) && !originLocationIds.includes(it['id'])));
    for (let item of newStops) {
      const serviceOptions = (item?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt))
      const info: LocationItem  = {
        ...item,
        shipmentId: null,
        serviceOptions,
      }
      if (val.filter(it => it['id'] == item.id)?.length > 0) continue;
      // Nếu stop là DROPOFF thì thêm vào cuối, Nếu là PICKUP thì thêm vào giữa trước điểm dropOff đầu tiên
      if (info.type == Const.TaskType.PICKUP) {
        const pickTasks = val.filter(it => it.type == Const.TaskType.PICKUP) || [];
        const dropTasks = val.filter(it => it.type == Const.TaskType.DROPOFF) || [];
        val = [...pickTasks, { ...info }, ...dropTasks];
      } else if (info.type == Const.TaskType.DROPOFF){
        val.push(info);
      }
    }
    return val;
  }

  // khi thêm pickup, dropoff thì phải update lại mảng location truyền vào drag and drop
  public static getListDeliveryInfo_FromStopChange_MultiPickDrop(currentLocations: LocationItem[] = [], originLocation: LocationItem[] = [], stopsChangeData) {
    let val: Array<LocationItem> = [];
    const deletedIds: any[] = stopsChangeData.deleted ?? [];
    const newShipments = stopsChangeData.new ?? [];
    const newShipmentIds = newShipments.map(it => it.id);
    const originLocationShipmentIds = [];
    originLocation.forEach(item => {
      if (!originLocationShipmentIds.includes(item.shipmentId)) originLocationShipmentIds.push(item.shipmentId);
    });

    val = currentLocations.filter(it => !deletedIds.includes(it.shipmentId))
      ?.filter(it => !(!newShipmentIds.includes(it.shipmentId) && !originLocationShipmentIds.includes(it.shipmentId)));
    
    let count = Math.round(val.length / 2);
    for (let shipment of newShipments) {
      count += 1;
      if (val.filter(it => it.shipmentId == shipment.id)?.length > 0) continue;
      for (let key of ['pickInfo', 'dropInfo']) {
        const serviceOptions = (shipment[key]?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt))
        const info: LocationItem  = {
          ...shipment[key],
          shipmentId: count,
          serviceOptions,
          items: shipment.items,
        }
        val.push(info);
      }
    }
    return val;
  }

  // convert dữ liệu từ model sang mảng location truyền vào drag and drop
  // multiDrop thì phải sort theo sortedTaskIds
  public static multiDropLocationBuilder_FromModelOrder(modelOrder: ResponseAdminOrderDetail) {
    let val: Array<LocationItem> = []; 
    const sortedTaskIds: Array<Array<string>> = modelOrder?.sortedTaskIds || [];
    let pickInfos = [];
    let dropInfos = [];
    for (let shipment of modelOrder?.metadata?.shipments) {
      const itemIds : any[] = shipment.itemIds ?? [];
      const items = modelOrder.items?.filter(it => itemIds.includes(it.id)) || [];
      const deliveryInfos = shipment.deliveryInfos || [];
      const pickInfo = deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
      const dropInfo = deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
      if (pickInfo) pickInfos.push({ ...pickInfo, items });
      if (dropInfo) dropInfos.push({ ...dropInfo, items });
    }
    if (Utils.isArrayNotEmpty(sortedTaskIds)) {
      // case multiDrop, thì phần tử đầu tiên luôn là pickup
      const pickId =  sortedTaskIds[0]?.[0];
      let pickLocation = pickInfos.filter(it => pickId && pickId == it.id)[0];
      if (pickLocation) {
        const serviceOptions = (pickLocation?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt));
        let allItems: Array<any> = [];
        if (pickInfos?.length) {
          pickInfos?.map((info) => {
            if (info.items?.length) {
              allItems = [...allItems, ...info.items];
            }
          });
        }
        pickLocation = { ...pickLocation, shipmentId: null, serviceOptions, items: allItems};
        val.push(pickLocation);
      }
      // case multiDrop, từ phần tử thứ 2 là dropoff
      for (let i=1; i<sortedTaskIds.length; i++) {
        const dropIds = sortedTaskIds[i];
        let dropLocation = dropInfos.filter(it => dropIds.includes(it.id))[0];
        if (dropLocation) {
          const serviceOptions = (dropLocation?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt));
          dropLocation = { ...dropLocation, shipmentId: null, serviceOptions}
          val.push(dropLocation);
        }
      }
    } else {
      // Nếu ko tồn tại sortedTaskIds, thì lấy list bình thường pickup trước dropoff
      for (let dropInfo of dropInfos) {
        const serviceOptions = (dropInfo?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt))
        const info: LocationItem  = { ...dropInfo, shipmentId: null, serviceOptions}
        val.push(info);
      }
      if (pickInfos?.[0]) {
        const serviceOptions = (pickInfos[0].serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt));
        let allItems: Array<any> = [];
        if (pickInfos?.length) {
          pickInfos?.map((info) => {
            if (info.items?.length) {
              allItems = [...allItems, ...info.items];
            }
          });
        }
        let pickLocation  = { ...pickInfos[0], shipmentId: null, serviceOptions, items: allItems };
        val.unshift(pickLocation);
      }
    }
    return val;
  }

  public static multiPickLocationBuilder_FromModelOrder(modelOrder: ResponseAdminOrderDetail) {
    let val: Array<LocationItem> = []; 
    const sortedTaskIds: Array<Array<string>> = modelOrder?.sortedTaskIds || [];
    let pickInfos = [];
    let dropInfos = [];
    for (let shipment of modelOrder?.metadata?.shipments) {
      const itemIds : any[] = shipment.itemIds ?? [];
      const items = modelOrder.items?.filter(it => itemIds.includes(it.id)) || [];
      const deliveryInfos = shipment.deliveryInfos || [];
      const pickInfo = deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
      const dropInfo = deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
      if (pickInfo) pickInfos.push({ ...pickInfo, items });
      if (dropInfo) dropInfos.push({ ...dropInfo, items });
    }
    if (Utils.isArrayNotEmpty(sortedTaskIds)) {
      // case multiPick, thì phần tử đầu tiên đến length -1 luôn là pickup
      for (let i=0; i<sortedTaskIds.length-1; i++) {
        const pickIds = sortedTaskIds[i];
        let pickLocation = pickInfos.filter(it => pickIds.includes(it.id))[0];
        if (pickLocation) {
          const serviceOptions = (pickLocation?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt));
          pickLocation = { ...pickLocation, shipmentId: null, serviceOptions}
          val.push(pickLocation);
        }
      }
      // case multiPick, phần tử cuối cung luôn là dropoff
      const dropId =  sortedTaskIds[sortedTaskIds.length-1]?.[0];
      let dropLocation = dropInfos.filter(it => dropId && dropId == it.id)[0];
      if (dropLocation) {
        const serviceOptions = (dropLocation?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt));
        let allItems: Array<any> = [];
        if (dropInfos?.length) {
          dropInfos?.map((info) => {
            if (info.items?.length) {
              allItems = [...allItems, ...info.items];
            }
          });
        }
        dropLocation = { ...dropLocation, shipmentId: null, serviceOptions, items: allItems};
        val.push(dropLocation);
      }
    } else {
      // Nếu ko tồn tại sortedTaskIds, thì lấy list bình thường pickup trước dropoff
      for (let pickInfo of pickInfos) {
        const serviceOptions = (pickInfo?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt))
        const info: LocationItem  = { ...pickInfo, shipmentId: null, serviceOptions}
        val.push(info);
      }
      if (dropInfos?.[0]) {
        const serviceOptions = (dropInfos[0].serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt));
        let allItems: Array<any> = [];
        if (dropInfos?.length) {
          dropInfos?.map((info) => {
            if (info.items?.length) {
              allItems = [...allItems, ...info.items];
            }
          });
        }
        let dropLocation  = { ...dropInfos[0], shipmentId: null, serviceOptions, items: allItems };
        val.push(dropLocation);
      }
    }
    return val;
  }

  public static multiPickDropLocationBuilder_FromModelOrder(modelOrder: ResponseAdminOrderDetail) {
    let val: Array<LocationItem> = []; 
    const sortedTaskIds: Array<Array<string>> = modelOrder?.sortedTaskIds || [];
    const shipments = modelOrder?.metadata?.shipments || [];
    for (let index = 0; index < shipments.length; index++) {
      const shipment = shipments[index];
      const deliveryInfos = shipment.deliveryInfos || [];
      const pickInfo = deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
      const dropInfo = deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
      const itemIds : any[] = shipment.itemIds ?? [];
      const items = modelOrder.items?.filter(it => itemIds.includes(it.id)) || [];
      val.push({ ...pickInfo , items, shipmentId: shipment.id, shipmentWarpId: `S-${shipment.warpId}` });
      val.push({ ...dropInfo, items, shipmentId: shipment.id, shipmentWarpId: `S-${shipment.warpId}` });
    }
    if (sortedTaskIds?.length) {
      val = AddRemoveStopsService.getLocationsFromSortedStopIds(sortedTaskIds, val);
    }
    return val;
  }

  public static getSortedTaskIds(locations) {
    const val = locations.map((info) => [info.id]);
    return val;
  }

  private static getLocationsFromSortedStopIds(sortedStopIds, locations) {
    let locationsClone = [...locations];
    let val = [];
    for (let index = 0; index < sortedStopIds.length; index++) {
      const stopIds = sortedStopIds[index];
      if (stopIds?.length) {
        const location = locations.find(info => info.id == stopIds[0]);
        if (location) {
          if (!val.find(item => item.id == location.id)) {
            val.push(location);
          }
          locationsClone = locationsClone.filter((item) => item.id != location.id)
        }
      }
    }
    if (locationsClone?.length) {
      val = [...val, ...locationsClone];
    }
    return val;
  }

}