import { Component } from "@angular/core";
import { BaseDetail } from '@base/detail';
import { Const } from "@const/Const";
import { FormControl, Validators } from "@angular/forms";
import { RoleManager } from "@services/role-manager";
import { InputHelper } from "@services/input-helper";
import { Utils } from "@services/utils";
import { ActivatedRoute } from "@angular/router";
import { Log } from "@app/services/log";
import { PaginationData } from "@app/model/PaginationData";
import { KeyLang } from '@app/locale';
import { MasterData } from "@services/master.data";

@Component({
  selector: "[user-detail]",
  templateUrl: "./detail.html",
  styleUrls: ["../detail.scss", "../../app.scss", "./style.scss"],
})
export class UserDetailComponent extends BaseDetail {
  protected formGroupDeclaration: FormGroupDeclaration = {
    email: {
      label: "Email",
      required: true,
      validators: this.validateEmail.bind(this) /*Validators.email*/,
      inputType: "email",
    },
    pw: { label: "Password", type: "string", required: true },
    fullName: { label: "Full name", required: true },
    phone: {
      label: "Phone",
      inputType: "tel",
      getValue: InputHelper.getValuePhone,
      formatValue: InputHelper.formatPhone,
    },
    roleIds: { label: "Roles", type: "array", required: true },
    orgId: { label: 'Organization', placeHolder: "Select Organization" }
  };

  get formInputKeys_basic() {
    return this.formInputKeys.filter((it) => it != "pickupAddresses");
  }

  get routerUrlBack() {
    return this.routeAdminUserList;
  }

  inputDataPw: string = '';

  hidePw = true;
  txtHintPw = "";
  passwordVisible = false;
  clientDeveloperKeyVisible = false;
  clientDeveloperApiKey: string = undefined;
  isClientUser: boolean = false;
  isApiKeyLoading: boolean = false;
  allRoles = [];
  activities: PaginationData = new PaginationData();
  listClients = [];
  listCarriers = [];
  // showClientHint = true;
  // isLoadClient = false;

  get avatarUrl(): string {
    return "assets/img/avatar2.png";
  }
  get isAvaDefault(): boolean {
    return this.avatarUrl == "assets/img/avatar2.png";
  }
  get shouldShowBtnEdit() {
    return super.shouldShowBtnEdit;
  }
  get shouldShowBtnAdd() {
    return super.shouldShowBtnAdd;
  }
  get shouldShowBtnSave() {
    return super.shouldShowBtnSave;
  }

  txtFullName = "";
  txtRole = "";
  txtPassword = "";
  txtNewAccount = "";
  isLoadingWarehouse;
  allWarehouses = [];
  organizations = [];

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
    this.allRoles = RoleManager.getAllUserRoles(this.authUser);
  }
  protected get crudEntity(): string {
    return "users";
  }
  ngOnInit() {
    super.ngOnInit();
    this.getAllWarehouses();
    this.getOrganizations();
  }

  setupLanguage() {
    this.txtHintPw = this.text(KeyLang.Txt_PwHint, [Const.password_len_min, Const.password_len_max]);
    this.txtFullName = this.text(KeyLang.Txt_FullName);
    this.txtRole = this.text(KeyLang.Txt_Role);
    this.txtPassword = this.text(KeyLang.Txt_Password);
    this.txtNewAccount = this.text(KeyLang.Txt_NewAccount);
  }

  get isMyAccount() {
    return this.model && this.authUser && this.model._id == this.authUser._id;
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any) {
    super.handleNavigationEnd(url, prevQueryParam);
  }

  protected createFormInput(model = undefined) {
    if (this.isCreateNew) {
      this.formGroupDeclaration["pw"].required = true;
      this.formGroupDeclaration["pw"].validators = null;
      this.formGroupDeclaration["pw"].readOnly = false;
    } else {
      this.formGroupDeclaration["pw"].required = false;
      this.formGroupDeclaration["pw"].validators = null;
      this.formGroupDeclaration["pw"].readOnly = true;
    }
    super.createFormInput(model);
    if (model) {
      this.onRoleChange();
    }
  }

  protected beforeBindModel(model): any {
    if (!model.fullName) {
      model.fullName = this.getFullName(model);
    }
    model.roleIds = model.roles
      .map((it) => it._id)
      .sort((a, b) => {
        return a > b ? 1 : a < b ? -1 : 0;
      });
    if (model.client) {
      model.clientId = model.client.id;
      this.listClients = [model.client];
    }
    if (model.carrier) {
      model.carrierId = model.carrier.id;
      if (!Utils.isArrayNotEmpty(this.listCarriers)) {
        this.listCarriers = [model.carrier];
      }
    }
    return model;
  }

  protected getApiUrl(): string {
    return Const.APIURI_USERS;
  }

  protected canDelete(model: any): boolean {
    return true;
  }

  protected bindDataModel(model) {
    super.bindDataModel(model);
    this.onRoleChange();
  }

  protected setEnableFormGroup(enable: boolean) {
    super.setEnableFormGroup(enable);
    if (enable && !RoleManager.canAccessUsers(this.authUser)) {
      this.formInput.get("roleIds").disable();
    }
  }

  protected getPageTitle(model): string {
    return model.fullName || model.email;
  }

  protected getMsgConfirmDelete(): string {
    return "Delete account " + (this.model.fullName || this.model.email) + "?";
  }

  protected onCreateSuccess(resp) {
    super.onCreateSuccess(resp);
  }

  protected onUpdateSuccess(resp) {
    Log.d("onUpdateSuccess resp: ", resp);
    super.onUpdateSuccess(resp);
    if (this.isMe(this.model)) {
      this.appComponent.myProfileChanged();
    }
  }

  hasRoleClient(roleIds): boolean {
    return Utils.isArrayNotEmpty(roleIds) && roleIds.includes(RoleManager.clientId);
  }

  get hasRoleCarrier(): boolean {
    let roleIds = this.formInput.get("roleIds").value;
    if (!Utils.isArrayNotEmpty(roleIds)) {
      return false;
    }
    // Nếu có role carrier hoặc driver thì user phải thuộc 1 carrỉe nào đó -> cần phải gán carrierId
    return roleIds.includes(RoleManager.carrierId) || roleIds.includes(RoleManager.driverId);
  }

  validateEmail(input: FormControl): any {
    if (input.value === null || input.value === undefined) {
      return { required: true };
    }
    let str = (input.value || '').trim();
    if (str == "sysadmin" || str == "root") {
      // 1 số trường account đặc biệt ko cần validate email
      if (this.model && this.model.email == str) {
        return null;
      }
    }
    // https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    return Validators.email(input);
  }

  onRoleChange() {
    let key = "clientId";
    if (this.hasRoleClient(this.formInput.get("roleIds").value)) {
      if (!this.formGroupDeclaration[key]) {
        let bindData;
        if (this.model) {
          bindData = this.model[key];
        }
        this.addItemToFormGroup(
          key,
          { label: "Customer", placeHolder: "Select customer", required: true, type: "string" },
          bindData
        );
      }
    } else {
      if (this.formGroupDeclaration[key]) {
        this.removeFormGroupItem(key);
      }
    }
    key = "carrierId";
    if (this.hasRoleCarrier) {
      if (!this.formGroupDeclaration[key]) {
        let bindData;
        if (this.model) {
          bindData = this.model[key];
        }
        this.addItemToFormGroup(
          key,
          { label: "Carrier", placeHolder: "Select carrier", required: true, type: "string" },
          bindData
        );
      }
    } else {
      if (this.formGroupDeclaration[key]) {
        this.removeFormGroupItem(key);
      }
    }

    key = "warehouseIds";
    if (this.hasWarehouseRole) {
      if (!this.formGroupDeclaration[key]) {
        let bindData;
        if (this.model) {
          bindData = this.model[key];
        }
        this.addItemToFormGroup(
          key,
          { label: "Cross Dock Warehouse", placeHolder: "Select Warehouse", required: true, type: "string" },
          bindData
        );
      }
    } else {
      if (this.formGroupDeclaration[key]) {
        this.removeFormGroupItem(key);
      }
    }
  }

  // getItemPickupAddress(index: number) {
  //   return (<FormArray>this.formInput.get("pickupAddresses")).at(index);
  // }

  // onBtnAddPickupAddress() {
  //   this.getFormArray("pickupAddresses").push(new FormControl());
  // }

  // onBtnRemovePickupAddress(index: number) {
  //   this.confirmDeletion({
  //     message: `Remove item at index ${index}?`,
  //     fnOk: () => {
  //       this.getFormArray("pickupAddresses").removeAt(index);
  //     },
  //   });
  // }

  // onClickAvatar() {}

  get hasWarehouseRole(): boolean {
    let roleIds = this.formInput.get("roleIds").value;
    if (!Utils.isArrayNotEmpty(roleIds)) {
      return false;
    }
    for (let roleId of roleIds) {
      if (roleId == RoleManager.warehouseAdminId || roleId == RoleManager.warehouseOperatorId) {
        return true;
      }
    }
    return false;
  }

  isLoadingOrganization: boolean = false;
  private getOrganizations() {
    this.isLoadingOrganization = true;
    const url = Const.APIV2(`${Const.APIURI_ORGANIZATIONS}?limit=-1`);
    this.api.GET(url).subscribe(
      (resp) => {
        this.isLoadingOrganization = false;
        this.organizations = resp.data.list_data;
      },
      (err) => {
        this.isLoadingOrganization = false;
        this.showErr(err);
      }
    );
  }

  private getAllWarehouses() {
    this.isLoadingWarehouse = true;
    const filter = {warehouseType: Const.WarehouseTypes.crossdock};
    const url = `${Const.APIURI_WAREHOUSES}?filter=${encodeURIComponent(JSON.stringify(filter))}&limit=-1`;
    this.api.GET(url).subscribe(
      (resp) => {
        Log.d('xxx getAllWarehouses done ', resp);
        this.isLoadingWarehouse = false;
        this.allWarehouses = resp.data.list_data;
      },
      (err) => {
        this.isLoadingWarehouse = false;
        this.showErr(err);
      }
    );
  }

  getWarehouseName(warehouse) {
    return warehouse?.name;
  }

  generateDeveloperApiKeyForClient() {
    this.startProgress();
    this.isApiKeyLoading = true;
    this.api.POST(`${Const.APIURI_CLIENTS}/${this.model.client.id}/create_apikey`,{userEmail:this.model.email}).subscribe(
      (resp) => {
        this.clientDeveloperKeyVisible = false;
        this.model.developerApiKey = resp.data.token;
        this.clientDeveloperApiKey = this.getShowApiKeyText(this.model.developerApiKey, this.clientDeveloperKeyVisible);
        this.stopProgress();
        this.isApiKeyLoading = false;
        this.showSuccess("New API key generated.");
      },
      (err) => {
        this.showErr(err);
        this.isApiKeyLoading = false;
        this.stopProgress();
      }
    );
  }

  protected onGetDetailSuccess(data) {
    this.isClientUser = this.hasRoleClient(data.roles.map((it) => it._id));
    this.clientDeveloperApiKey = this.getShowApiKeyText(data.developerApiKey, this.clientDeveloperKeyVisible);
    return data;
  }

  private getShowApiKeyText(apiKey: string, visible: boolean) {
    if (visible) return apiKey;
    else if (apiKey) return "********************************" + apiKey.slice(-8);
  }

  deleteDeveloperApiKey() {
    this.confirmDeletion({
      message: "Deleting the key will prevent the client from calling our API Gateway. Are you sure you want to delete?",
      fnOk: () => {
        this.startProgress();
        this.api.POST(`${Const.APIURI_CLIENTS}/${this.model.client._id}/delete_apikey`).subscribe(
          (resp) => {
            this.clientDeveloperApiKey = undefined;
            this.model.developerApiKey = undefined;
            this.clientDeveloperKeyVisible = false;
            this.stopProgress();
            this.showSuccess("API key deleted.");
          },
          (err) => {
            this.showErr(err);
            this.stopProgress();
          }
        );
      },
    });
  }

  regenerateDeveloperApiKey() {
    this.confirmYesNo("Generating a new key will make the existing API key no longer available. Do you want to generate a new key?", () => {
      this.startProgress();
        this.api.POST(`${Const.APIURI_CLIENTS}/${this.model.client.id}/regenerate_apikey`,{userEmail:this.model.email}).subscribe(
          (resp) => {
            this.clientDeveloperKeyVisible = false;
            this.model.developerApiKey = resp.data.token;
            this.clientDeveloperApiKey = this.getShowApiKeyText(this.model.developerApiKey, this.clientDeveloperKeyVisible);
            this.stopProgress();
            this.showSuccess("New API key generated.");
          },
          (err) => {
            this.showErr(err);
            this.stopProgress();
          }
        );
    });
  }

  copyDeveloperApiKey() {
    Utils.copyTextToClipboard(this.model.developerApiKey, (e) => {
      if (e) {
        this.showErr("Cannot copy API Key to clipboard");
      } else {
        this.showSuccess("API Key has already been copied to the clipboard");
      }
    });
  }
  get pwPolicy() { return MasterData.pwPolicyText }
}
