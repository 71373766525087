import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { OrderStatus } from "@app/admin/orders/order-status";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { DialogsModule } from "@dialogs/dialogs.module";
import { Utils } from "@services/utils";
import { WarpId } from "@wearewarp/universal-libs";


@Component({
    selector: '[shipment-structure-detail]',
    templateUrl: './shipment_detail.html',
    styleUrls: ['./styles.scss']
})
export class ShipmentStructureDetail extends BaseComponent {
    @Input() shipment: any = null
    @Input() clientMeatadata: any = null
    @Input() compact
    @Input() isShowActualTime: boolean = false
    pickup: any = null
    dropoff: any = null
    status: string = ''
    @Input() editMode: boolean = false
    @Output() deliveryInfoUpdated: EventEmitter<any> = new EventEmitter<any>();
    shouldShowRevenue: boolean = false


    ngOnInit(): void {
        // this.pickup = (this.shipment?.deliveryInfos || []).filter(it => it.type === 'PICKUP')[0]
        // this.dropoff = (this.shipment?.deliveryInfos || []).filter(it => it.type === 'DROPOFF')[0]
    }
    ngOnChanges(): void {
        this.pickup = (this.shipment?.deliveryInfos || []).filter(it => it.type === 'PICKUP')[0]
        this.dropoff = (this.shipment?.deliveryInfos || []).filter(it => it.type === 'DROPOFF')[0]
        this.status = this.getStatusShipment(this.shipment.status)
        this.shouldShowRevenue = this.shipment.parentId && this.compact !== true && this.clientMeatadata && this.clientMeatadata['sub-shipment-revenue'] === 'true'
    }

    public copyTrackingCode() {
        Utils.copyTextToClipboard(this.shipment.trackingCode, (e) => {
            if (e) {
                this.showErr("Cannot copy tracking number to clipboard");
            } else {
                this.showSuccess(
                    "Tracking number has already been copied to the clipboard"
                );
            }
        });
    }

    public copyWarpId() {
        Utils.copyTextToClipboard(this.shipment.warpId, (e) => {
            if (e) { } else {
                this.showSuccess(
                    `Warp ID ${this.shipment.warpId} been copied to the clipboard`
                );
            }
        });
    }

    onUpdated() {
        this.deliveryInfoUpdated?.emit()
    }

    onCostUpdate(data) {
        this.deliveryInfoUpdated?.emit()
    }

    async downloadBOL(event, shipment) {
        if (!shipment) {
            return;
        }

        let forceUpdate = false;
        if (event.ctrlKey || event.metaKey) {
            forceUpdate = true;
        };

        shipment.bolInfo = {
            isDownloading: true
        }

        //tạo mới nếu chưa có BOL
        if (!shipment.bolFileId || forceUpdate) {
            const resp = await this.api.POST(`${Const.APIV2(Const.APIURI_SHIPMENTS)}/${shipment.id}/bol`).toPromise();
            const data = resp.data;
            shipment.bolFileId = data?.bolFileId
        }

        shipment.bolInfo = {
            _id: shipment.bolFileId,
            type: 'application/pdf',
            name: `BOL-${WarpId.showShipment(shipment)}`
        };
        this.downloadAttachedFile(shipment.bolInfo)
    }
    onBtnEditStatus(item) {
        if(this.isAdminReadOnlyRole || item.status == Const.OrderStatus.removed || item?.isParentSubShipment){
            return;
        }
        let modelShipment = item
        modelShipment._id = item.warpId;
        DialogService.openFormDialog1(OrderStatus, {
            nzComponentParams: {
                updateSuccess: (resp)=>{
                    this.deliveryInfoUpdated?.emit()
                },
                model: modelShipment,
                closeOnSuccess: true,
            },
            nzClassName: 'modal-no-padding',
            nzCentered: true,
        })
    }
    
    onBooked(id) {
        this.shipment.externalId = id
        this.deliveryInfoUpdated.emit()
    }
    
}