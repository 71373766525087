<div style="padding-top: 8px">
  <div *ngIf="hasTitle" class="bottom15">
    <span class="title">Customer & Equipment</span>
    <span *ngIf="isTurnOnEditMode">
      <a class="edit-item-shipment-entry" (click)="onBtnEditEquipment()">
        <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
      </a>
    </span>
  </div>
  <div class="row">
    <img alt="Customer" class="icon" src="assets/img/ic_shipment_info_person.svg" />
    <span>{{client?.name}}</span>
  </div>
  <div *ngIf="parentClient" class="row">
    <img alt="Customer" class="icon" src="assets/img/ic_shipment_info_person.svg" />
    <span>Parent: {{parentClient?.name}}</span>
  </div>
  <div class="row">
    <img alt="Truck type" class="icon" src="assets/img/ic_shipment_info_truck.svg" />
    <span>{{shipmentType}}</span>
  </div>
  <div *ngIf="isUsingCrossdock" class="row">
    <img alt="Truck type" class="icon" src="assets/img/ic_shipment_info_truck.svg" />
    <span>Using Crossdock</span>
  </div>
  <ng-container *ngIf="getTruckTypeInfo()">
    <div class="row">
      <img alt="Truck Description" class="icon" src="assets/img/ic_shipment_info_tick.svg" />
      <span>{{getTruckTypeInfo()}}<i *ngIf="isLTLShipment()" nz-icon nzType="warning" nzTheme="outline" nz-tooltip [nzTooltipTitle]="equipmentWarning" style="padding-left: 10px; color: #faad14;"></i></span>
    </div>
  </ng-container>
  <div *ngIf="displayInfo.routeMileage != 'N/A' || displayInfo.routeHours != 'N/A'" class="row">
    <img class="icon icon-route-mileage-hour" src="/assets/img/dispatch-icons/route.svg" />
    <span>{{ displayInfo.routeMileage }} mi - {{ displayInfo.routeHours }} hr</span>
  </div>
  <div *ngIf="!hasTitle" class="line-divider"></div>
</div>
