<div class="flex" *ngFor="let item of uiCollapseState.shipments; let i = index;">
  <div batch-location-side-indicator [isDone]="false"></div>
  <div class="flex1 bottom20">
    <nz-collapse class="collapse-form-location">
      <nz-collapse-panel [nzHeader]="tplShipment" [(nzActive)]="item.isExpand" [nzShowArrow]="false">
        <ng-template #tplShipment>
          <div class="flex">
            <div class="flex1">
              <span>Shipment {{ i+1 }} : {{ getCustomerName(item) || ''}}</span>
              <span *ngIf="item.isAddNew" class="icon-new-location">New</span>
            </div>
            <div *ngIf="item.data?.warpId" class="right20">
              WARP ID: <code>{{showShipmentWarpId(item.data?.warpId)}}</code>
            </div>
            <button *ngIf="canRemoveShipment(i)" nz-button nzType="text" nzDanger 
              (click)="onBtnRemoveShipmentInfo(i, item.data?.warpId);$event.stopPropagation();" style="position: relative; top: -5px;">
              <span nz-icon nzType="close-square" nzTheme="outline"></span>
              Remove
            </button>
          </div>
        </ng-template>
        <div class="shipment-info">
          <div class="pick-info">
            <nz-collapse class="collapse-form-location">
              <nz-collapse-panel [nzHeader]="tplPick" [nzShowArrow]="false">
                <ng-template #tplPick>
                  <div class="mainTitleCollapse">
                    <img src="assets/img/location-icon.svg">
                    <span class="pickup-tag">Pickup</span> 
                    <span class="left5"> {{item.data?.pickInfo?.locationName}}</span>
                    <span class="font-regular left10"> {{getAddressText(item.data?.pickInfo?.addr)}}</span>
                  </div>
                  <div nz-row class="subTitleCollapse">
                    <div nz-col nzXXl="12" nzXl="12" nzLg="12" class="appointmentDate flex" style="flex-wrap: wrap;">
                      <div class="flex right20">
                        <img src="assets/img/appointment-date.svg" style="align-self: flex-start; margin-right: 5px;">
                        <div [innerHTML]="getTimePeriods(item.data?.pickInfo)"></div>
                      </div>
                      <div *ngIf="isAppointmentRequired(item.data?.pickInfo)">
                        <img src="assets/img/appointment-date.svg" style="margin-right: 5px;">
                        <span class="font-medium">Requires Appointment</span>
                      </div>
                    </div>
                    <div nz-col nzXXl="12" nzXl="12" nzLg="12" class="serviceOptions">
                      <img src="assets/img/service-options-icon.svg">
                      {{getServiceOptions(item.data?.pickInfo)}}
                    </div>
                  </div>
                </ng-template>
                <div nz-row class="info">
                  <div nz-col nzSpan="10">
                    <div class="label-info primaryContact">Primary Contact: {{getPrimaryContact(item.data?.pickInfo)}}</div>
                    <div class="label-info referenceNo">Reference No: {{getReferenceNo(item.data?.pickInfo)}}</div>
                    <div class="label-info instructions">Instructions: {{getInstructions(item.data?.pickInfo)}}</div>
                  </div>
                  <nz-divider class="divider-info" nzType="vertical"></nz-divider>
                  <div nz-col nzSpan="10" nzOffset="1">
                    <div class="label-info secondaryContact">Secondary Contact: {{getSecondaryContact(item.data?.pickInfo)}}</div>
                    <div class="label-info accessCode">Access Code: {{getAccessCode(item.data?.pickInfo)}}</div>
                    <div class="label-info notes">Notes: {{getNotes(item.data?.pickInfo)}}</div>
                  </div>
                </div>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
          <div class="pick-info top20">
            <nz-collapse class="collapse-form-location">
              <nz-collapse-panel [nzHeader]="tplDrop" [nzShowArrow]="false">
                <ng-template #tplDrop>
                  <div class="mainTitleCollapse">
                    <img src="assets/img/location-icon.svg">
                    <span class="dropoff-tag">Delivery</span> 
                    <span class="left5"> {{item.data?.dropInfo?.locationName}}</span>
                    <span class="font-regular left10"> {{getAddressText(item.data?.dropInfo?.addr)}}</span>
                  </div>
                  <div nz-row class="subTitleCollapse">
                    <div nz-col nzXXl="12" nzXl="12" nzLg="12" class="appointmentDate flex" style="flex-wrap: wrap;">
                      <div class="flex right20">
                        <img src="assets/img/appointment-date.svg" style="align-self: flex-start; margin-right: 5px;">
                        <div [innerHTML]="getTimePeriods(item.data?.dropInfo)"></div>
                      </div>
                      <div *ngIf="isAppointmentRequired(item.data?.dropInfo)">
                        <img src="assets/img/appointment-date.svg" style="margin-right: 5px;">
                        <span class="font-medium">Requires Appointment</span>
                      </div>
                    </div>
                    <div nz-col nzXXl="12" nzXl="12" nzLg="12" class="serviceOptions">
                      <img src="assets/img/service-options-icon.svg">
                      {{getServiceOptions(item.data?.dropInfo)}}
                    </div>
                  </div>
                </ng-template>
                <div nz-row class="info">
                  <div nz-col nzSpan="10">
                    <div class="label-info primaryContact">Primary Contact: {{getPrimaryContact(item.data?.dropInfo)}}</div>
                    <div class="label-info referenceNo">Reference No: {{getReferenceNo(item.data?.dropInfo)}}</div>
                    <div class="label-info instructions">Instructions: {{getInstructions(item.data?.dropInfo)}}</div>
                  </div>
                  <nz-divider class="divider-info" nzType="vertical"></nz-divider>
                  <div nz-col nzSpan="10" nzOffset="1">
                    <div class="label-info secondaryContact">Secondary Contact: {{getSecondaryContact(item.data?.dropInfo)}}</div>
                    <div class="label-info accessCode">Access Code: {{getAccessCode(item.data?.dropInfo)}}</div>
                    <div class="label-info notes">Notes: {{getNotes(item.data?.dropInfo)}}</div>
                  </div>
                </div>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
          <div class="top20">
            <div nz-row class="item" style="margin: 0px" *ngFor="let shipmentItem of item.data?.items">
              <div nz-col nzSpan="20">
                <div class="item-specifications-info">
                  <div class="flex">
                    <div class="item-name item-label right10">{{shipmentItem.name}}</div>
                    <div *ngIf="shipmentItem.sum?.desc">{{shipmentItem.sum?.desc}},</div>
                    <div *ngIf="!shipmentItem.sum?.desc">
                      <span *ngIf="shipmentItem.qty">{{shipmentItem.qty}} {{shipmentItem.qtyUnit}}</span>
                      <span *ngIf="shipmentItem.weight">, {{shipmentItem.weight}} {{shipmentItem.weightUnit}}</span>
                      <span *ngIf="shipmentItem.length || shipmentItem.shipmentItem || shipmentItem.height">
                        <span>, {{shipmentItem.length}}</span>
                        <span>x{{shipmentItem.width}}</span>
                        <span>x{{shipmentItem.height}} </span>
                        <span>{{shipmentItem.sizeUnit}},</span>
                      </span>
                    </div>
                    <div *ngIf="shipmentItem.itemValue" class="left10">{{formatItemValue(shipmentItem.itemValue)}}</div>
                  </div>
                </div>
                <div class="item-identification-info">
                    <span class="item-id item-label">ID: {{shipmentItem.id}},</span>
                    <span class="item-barcode item-label">Barcode: {{getItemBarcode(shipmentItem)}}</span>
                    <span class="item-service">Service: {{getItemService(shipmentItem)}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
</div>

<button nz-button nzSize="large" (click)="onBtnAddShipmentInfo()" style="margin-left: 40px;">Add Another Shipment</button>
