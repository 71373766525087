<form [formGroup]="formInput" nz-form style="margin: 0px; padding: 0px;">
  <div style="display: flex; flex-wrap: wrap;">
  <div class="form-item" style="width: 400px;">
      <div class="form-label-v2">Keyword</div>
      <search-box #searchBox (doSearch)="doSearch($event)" [placeHolder]="txtHintSearch" [searchKeyword]="searchKeyword" style="width: 100%;"></search-box>
  </div>

  <ng-container *ngFor="let key of ['roleIds', 'clientId', 'carrierId']">
    <div class="form-item" style="min-width: 170px;" >
      <div class="form-label-v2">{{getLabel(key)}}</div>
      <ng-container [ngSwitch]="key">
        <nz-select nzBackdrop="true" *ngSwitchCase="'roleIds'" nzAllowClear style="width: 100%;" nzMode="multiple"
          [formControlName]="key"
          [nzPlaceHolder]="getPlaceHolder(key)"
          (ngModelChange)="onChange($event, key)">
          <nz-option *ngFor="let role of allRoles" [nzLabel]="getRoleName(role)" [nzValue]="role._id">
          </nz-option>
        </nz-select>
        <nz-select nzBackdrop="true" *ngSwitchCase="'clientId'" nzAllowClear style="width: 100%;" nzMode="multiple"
          [formControlName]="key"
          [nzPlaceHolder]="getPlaceHolder(key)"
          (ngModelChange)="onChange($event, key)">
          <nz-option *ngFor="let item of allClients" [nzLabel]="item.name" [nzValue]="item.id">
          </nz-option>
        </nz-select>
        <nz-select nzBackdrop="true" *ngSwitchCase="'carrierId'" nzAllowClear style="width: 100%;" nzMode="multiple"
          [formControlName]="key"
          [nzPlaceHolder]="getPlaceHolder(key)"
          (ngModelChange)="onChange($event, key)">
          <nz-option *ngFor="let item of allCarriers" [nzLabel]="item.basicInfo?.name" [nzValue]="item.id">
          </nz-option>
        </nz-select>
      </ng-container>
    </div>
  </ng-container>

</div>
</form>
