import { BaseList } from '../base/list';
import { Component } from '@angular/core';
import { Const } from '@app/const/Const';
import { AddressUS, DeliveryInfo } from '@wearewarp/types/data-model';
import { DialogService } from '@dialogs/dialog.service';
import { ConfirmPod } from '../shipments/confirm-pod';
import { ApiService } from '@services/api.service';
import { Utils } from '@services/utils';
import { environment } from '@env/environment';
import { Log } from '@services/log';
import _ from "underscore";
import { InputHelper } from '@services/input-helper';
import { MasterData } from '@services/master.data';
import { ShipmentFilter } from './filters/index';
import { LoadingComponent } from '../components/loading/loading.component';

@Component({
  selector: 'shipment-invoice-list',
  templateUrl: './list.html',
  styleUrls: ['../list.scss']
})

export class ShipmentInvoiceList extends BaseList {

  public warehouses = [];

  constructor() {
    super();
    this.apiVersion = 'v2';
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadData();
    this.autoShrinkSideBar();
  }

  private loadData() {
    this.api.GET(`${Const.APIURI_WAREHOUSES}?&limit=-1`).subscribe((response) => {
      this.warehouses = _.get(response, ["data", "list_data"], []);
    });
  }

  protected getApiUrl(): string {
    return Const.APIURI_INVOICES_ORDERS;
  }

  protected activeAction: String = "confirm";

  // Hiển thị multi-stop shipment dạng children
  public mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};

  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, isExpanded: boolean): void {
    if (!isExpanded) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.key === d.key)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
    const stack: TreeNodeInterface[] = [];
    const array: TreeNodeInterface[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: true });
    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level! + 1, expand: false, parent: node });
        }
      }
    }
    return array;
  }

  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: boolean }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }

  toggleChildren(originalItem: TreeNodeInterface, mappedItem: TreeNodeInterface) {
    mappedItem.expand = !mappedItem.expand
    this.collapse(this.mapOfExpandedData[originalItem.key], mappedItem, mappedItem.expand);
  }

  getShipmentId(obj: any) {
    if (obj.shipmentId) return parseInt(obj.shipmentId)
    return null;
  }

  getRouterLink(item) {
    let id = item.id;
    if (!this.isOrder(item)) {
      id = item?.parent?.id;
    }
    return [this.routeAdminShipmentList, id];
  }

  getTrackingCrossdock(item) {
    const id = item._id;
    if (id) return [this.routeAdminShipmentList, id, 'tracking-items'];
    return null;
  }

  hasCrossdock(item): boolean {
    return item.isCrossDock;
  }

  private getEquipment(item) {
    let shipmentMode = item.shipmentMode?.name ?? '';
    let equipment = item.equipment?.name ?? '';
    if (equipment) {
      if (shipmentMode) {
        shipmentMode += '/';
      }
      shipmentMode += equipment;
    }
    return shipmentMode;
  }

  public getDeliveryInfoDate(deliveryInfo: DeliveryInfo, format: string = Const.FORMAT_GUI_DATETIME) {
    let str = super.getDeliveryInfoTime(deliveryInfo, { format: 'MMM D, YYYY \n h:mm a', appointmentFlag: '\n(Appointment Scheduled)' });
    return str || 'Requires appointment';
  }

  public getCountPodNotConfirmed(item) {
    // return item.podInfo.countPodNotConfirmed;
    return 0;
  }

  protected onGetDataSucceeded(resp: any): void {
    for (let item of this.listData) {
      if (item.invoiceFileId && !item.invoice?._id) {
        item.invoice = { _id: item.invoiceFileId };
      }
      item.isInvoiceSent = !!item.invoiceSent?.when;
      item.key = item.id;
      let getShipmentData = (record) => {
        let pickInfo = record?.deliveryInfos?.filter(it => it.type == Const.TaskType.PICKUP)[0];
        let dropInfo = record?.deliveryInfos?.filter(it => it.type == Const.TaskType.DROPOFF)[0];
        let { status, trackingCode, type, legs, subs } = record;
        return {
          status, trackingCode, type, legs, subs,
          addrPickFull: this.getAddressText(pickInfo?.addr),
          addrDropFull: this.getAddressText(dropInfo?.addr),
          addrPick: this.getStreetAddressText(pickInfo?.addr),
          addrDrop: this.getStreetAddressText(dropInfo?.addr),
          addrPickState: this.getStateAddressText(pickInfo?.addr),
          addrDropState: this.getStateAddressText(dropInfo?.addr),
          timePick: this.getDeliveryInfoDate(pickInfo),
          timeDrop: this.getDeliveryInfoDate(dropInfo),
          locationNamePick: pickInfo?.locationName || '',
          locationNameDrop: dropInfo?.locationName || '',
          clientSuccessRep: this.getFullName(record.clientSuccessRep),
          clientSalesRep: this.getFullName(record.clientSalesRep),
          dispatcher: this.getFullName(record.dispatcher),
          carrierSaleRep: this.getFullName(record.carrierSaleRep),
          client: this.getClientName(record),
          carrier: this.getCarrierName(record.carrier),
          customerRate: this.getMoneyValue(record.customerRate),
          carrierRate: this.getMoneyValue(record.carrierRate),
          margin: this.getMoneyValue(record.margin),
          refNum: this.getRefNum(record),
          shipmentType: this.getShipmentTypeName(record.shipmentType),
          tempRange: record.tempRange || "",
          equipment: this.getEquipment(record),
          jobIds: record.jobIds || [],
          pod: record.pod || {}
        }
      }
      let { shipmentIds = [], metadata } = item;
      item.orderId = item.warpId;
      item.children = [];
      item.displayInfo = {
        customerRate: this.getMoneyValue(metadata.customerRate || item.customerRate),
        carrierRate: this.getMoneyValue(metadata.carrierRate || item.carrierRate),
        margin: this.getMoneyValue(metadata.margin || item.margin),
      };
      if (shipmentIds.length) {
        if (shipmentIds.length > 1) {
          if (metadata && metadata.shipments && metadata.shipments.length) {
            let isCrossDock = false;
            metadata.shipments.map(record => {
              if (record.isCrossDock) isCrossDock = true;
              let displayInfo = getShipmentData(record);
              item.children.push({
                ...record,
                displayInfo,
                key: record.id,
                shipmentId: record.warpId,
                showStatusValue: true
              })
            });
            if (isCrossDock) {
              let shipment = metadata.shipments[0];
              item.displayInfo = getShipmentData(shipment);
              item.shipmentId = shipment.warpId;
              item.showStatusValue = true;
              item.children.shift();
            } else {
              let shipment = metadata.shipments[0];
              item.showStatusValue = false;
              item.shipmentId = shipment.warpId;
            }
          }
        } else {
          let shipment = metadata.shipments[0];
          item.displayInfo = {
            ...getShipmentData(shipment),
            ...item.displayInfo
          };
          item.shipmentId = shipment.warpId;
          item.showStatusValue = true;
        }
      } else {
        // dùng cho những bản ghi cũ (nhánh này sẽ ko bao giờ xảy ra)
        item.addrPick = this.getStreetAddressText(item.pickAddr || item.pickInfo?.addr);
        item.addrDrop = this.getStreetAddressText(item.dropAddr || item.dropInfo?.addr);
        item.addrPickState = this.getStateAddressText(item.dropAddr || item.dropInfo?.addr);
        item.addrDropState = this.getStateAddressText(item.dropAddr || item.dropInfo?.addr);
      }
    }
    // Từ listData bao gồm các item (có thể có item.children[]) => tạo dữ liệu để dùng cho nz-table có cấu trúc tree
    this.listData.forEach(item => {
      this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
    });
  }

  hasRoute(item): boolean {
    //xử lý cho FTL
    if (item.shipmentType == Const.ShipmentTypes.fullTruckLoad) {
      if (this.isOrder(item)) {
        //đối với FTL multi-stop chuyển jobids ra ngoài order để hiển thị đúng.
        item.displayInfo.jobIds = item?.metadata?.shipments?.[0]?.jobIds
      }
      else {
        return false; //không hiện icon dispatch với shipment của FTL
      }
    }

    let check = false;
    if (item.displayInfo) check = Utils.isArrayNotEmpty(item.displayInfo.jobIds);
    return check
  }

  // Shipment được tạo từ màn Freight Quote, sau khi lấy rating từ API Exfreight
  isExfreightShipment(item) {
    return (item.exfreightInfo?.route_number || item.source == Const.thirdPartyOrderSource.Exfreight);
  }

  getExfreightTooltip(item) {
    if (!item.exfreightInfo) return null;
    if (item.exfreightInfo.bookingResult) {
      return 'Exfreight booking info';
    } else {
      return 'Book Exfreight';
    }
  }

  getOrderFulTooltip(item) {
    if (this.isOrderful(item)) {
      return 'Orderful'
    }
  }

  public shouldShowLegIds(item) {
    const legs = item.displayInfo.legs || [];
    return legs.length;
  }

  public getLegIdText(item) {
    const legs = item?.legs ? [...item?.legs] : [];

    if (legs.length == 0) return 'N/A';
    const firstLeg = legs.shift();
    return this.showShipmentWarpId(firstLeg.warpId);
  }

  public getTrackingItems(item) {
    const { shipmentIds } = item;
    let firstShipmentId = shipmentIds.length ? shipmentIds[0] : null
    return [this.routeAdminShipmentList, firstShipmentId, 'tracking-items'];
  }

  public getListIds(items) {
    const ids = [];
    items.forEach((it, index) => {
      if (index > 0) {
        ids.push(this.showShipmentWarpId(it.warpId));
      }
    });
    return ids.join(', ');
  }

  isOrderful(item) {
    return (
      item?.thirdPartyInfo?.source === Const.thirdPartyClientCode.orderful ||
      item.source === Const.thirdPartyOrderSource.orderful
    )
  }

  isOrderfulFTL(item) {
    return (
      (
        item?.thirdPartyInfo?.source === Const.thirdPartyClientCode.orderful ||
        item.source === Const.thirdPartyOrderSource.orderful
      ) &&
      item.shipmentType === Const.ShipmentTypes.fullTruckLoad
    );
  }

  protected getDialogFilterClass(): { class: any, params?: any } {
    return { class: ShipmentFilter, params: { metadata: this.filterMetadata } }
  }

  getClientName(order) {
    if (order.parentId) return '';
    return order?.client?.name || order?.metadata?.shipments?.[0]?.client?.name;
  }

  onOpenConfirmPod(item) {
    DialogService.openDialog(ConfirmPod, {
      nzComponentParams: {
        model: item,
        onDone: invoice => {
          item.invoice = invoice;
        }
      },
      nzClosable: false,
      nzClassName: 'modal-no-padding modal-fullscreen',
      nzCentered: true,
    });
  }

  getStatusValue(item): string {
    if (typeof item.status == 'string') return item.status;
    return Const.OrderStatus.needCarrier;
  }

  checkStatusComplete(item): boolean {
    if (!this.isOrder(item)) {
      if (item?.status && [Const.OrderStatus.complete].includes(item.status)) {
        return true;
      }
    } else {
      const childrens = item?.metadata?.shipments || [];
      const checkCompleted = childrens.map(children => this.checkStatusComplete(children));
      return checkCompleted.filter(status => status).length == childrens.length
    }
    return false;
  }

  checkStatusCanceledOrReturned(item, status = ''): boolean {
    if (!this.isOrder(item)) {
      if (item?.status && item.status == status) {
        return true;
      }
    } else {
      const childrens = item?.metadata?.shipments || [];
      const check = childrens.map(children => this.checkStatusCanceledOrReturned(children, status));
      return check.filter(status => status).length == childrens.length
    }
    return false;
  }

  isShipmentCanceled(item): boolean {
    return this.checkStatusCanceledOrReturned(item, Const.OrderStatus.returned)
  }

  isShipmentReturned(item): boolean {
    return this.checkStatusCanceledOrReturned(item, Const.OrderStatus.canceled)
  }

  isShipmentCompleted(item) {
    return this.checkStatusComplete(item)
  }

  hasInvoice(item): boolean {
    if (item.invoiceFileId || (item.invoice && item.invoice.id)) return true;
    return false;
  }

  countConfirmPOD(item) {
    const shipments = item?.metadata?.shipments || [];
    let total = 0;
    for (let shipment of shipments) {
      total += shipment?.pod?.total
    }
    return total;
  }

  isShowConfirmPOD(item) {
    if (this.countConfirmPOD(item)) return true;
    return false;
  }

  downloadInvoice(item, event) {
    if (item.invoice?.isDownloading) return;
    let forceUpdate = false;
    if (event.ctrlKey || event.metaKey) {
      forceUpdate = true;
    };
    if (!forceUpdate) {
      return this.downloadAttachedFile(item.invoice);
    }
    item.invoice.isDownloading = true;
    // force Re-generate
    const url = `v2/${Const.APIURI_ORDERS}/invoice/${item.id}`;
    this.api.POST(url, {
      forceReGenerate: true
    }).subscribe(
      resp => {
        item.invoice = resp.data
        return this.downloadAttachedFile(item.invoice);
      }, err => {
        item.invoice.isDownloading = false;
        this.showErr(err);
      }
    );
  }

  showStatusValue(item: any): string {
    let str = this.getStatusOrder(this.getStatusValue(item.displayInfo));
    if (str == 'Canceled' && item.tonu?.carrierCost) {
      str = `${str} - TONU`;
    }
    return str;
  }

  shouldShowStatus(item): boolean {
    return item.showStatusValue
  }

  isWarehouseStatus(item): boolean {
    const { status, statusHistory = [] } = item
    return [Const.OrderStatus.inRouteToWareHouse, Const.OrderStatus.arrivedAtWareHouse].includes(status) && statusHistory.length;
  }

  getLinkWarehouse(item) {
    const { statusHistory = [] } = item
    let lastItem = statusHistory[statusHistory.length - 1];
    return lastItem && lastItem?.crossdockWarehouseId ? [this.routeAdminLocationList, lastItem?.crossdockWarehouseId] : null;
  }

  getWarehouseName(item): string {
    const { statusHistory = [] } = item
    let lastItem = statusHistory[statusHistory.length - 1];
    let crossdockWarehouseId = lastItem?.crossdockWarehouseId
    let warehouse = this.warehouses.find(item => item._id === crossdockWarehouseId)
    return warehouse ? warehouse.name : null;
  }

  onBtnInvoiceSent(item, value) {
    let isInvoiceSent = !!item.invoiceSent?.when;
    if (value == isInvoiceSent) {
      return;
    }
    let url = Const.APIV2(`${Const.APIURI_ORDERS}/${item.id}/invoice_sent`);
    item.isLoading = true;
    this.api.PUT(url, { value }).subscribe(
      resp => {
        Log.d('onBtnInvoiceSent resp: ', resp);
        item.isLoading = false;
        item.invoiceSent = resp?.data?.invoiceSent;
        item.isInvoiceSent = !!item.invoiceSent?.when;
      }, err => {
        this.showErr(err);
        item.isInvoiceSent = !!item.invoiceSent?.when;
        item.isLoading = false;
      }
    );
  }

  public isExporting = false;
  onBtnExport() {
    this.isExporting = true;
    let query = this.prepareParamGetList();
    query.download = 1;
    if (!query.filter && !query.search) {
      query.exportAll = 1;
    }
    this.api.postExport(`${Const.APIV2('orders')}/export`, query).subscribe(
      resp => {
        ApiService.handleDownloadResponse(resp);
        this.isExporting = false;
      }, err => {
        this.showErr(err);
        this.isExporting = false;
      }
    );
  }
  public isOTPReportExporting = false;
  onBtnOTPReportExport() {
    this.isOTPReportExporting = true;
    let query = this.prepareParamGetList();
    query.download = 1;
    if (!query.filter && !query.search) {
      query.exportAll = 1;
    }
    this.api.postExport(`${Const.APIV2('orders')}/otp-report-export`, query).subscribe(
      resp => {
        ApiService.handleDownloadResponse(resp);
        this.isOTPReportExporting = false;
      }, err => {
        this.showErr(err);
        this.isOTPReportExporting = false;
      }
    );
  }

  isOrder(item) {
    if (item.shipmentIds) return true;
    return false;
  }

  getFirstShipment(order) {
    return order?.metadata?.shipments?.[0] || {}
  }

  getTrackingLink(item) {
    let trackingCode = this.getTrackingCode(item);
    return `${environment.trackingWebUrl}/${trackingCode}`;
  }

  getTrackingCode(item) {
    return item.code || item.trackingCode || this.getFirstShipment(item).trackingCode
  }

  private reloadAtFirstPage() {
    if (this.currentPage == 1) {
      this.onBtnRefresh();
    } else {
      this.routeWithQueryUrl({ page: 1 });
    }
  }

  shouldShowShipmentType(item) {
    if (item.parentId) return false;
    return true;
  }

  shouldShowEquipmentAndTemp(item) {
    if (item.parentId) return false;
    return item.equipments || item.tempRange;
  }

  getRefNum(item) {
    if (!item.parent) {
      let stopInfo;
      if (item.deliveryInfos) {
        stopInfo = item.deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
      } else {
        stopInfo = item.pickInfo;
      }
      let refNum = "";
      if (stopInfo && stopInfo.refNums) refNum = stopInfo.refNums.join(", ");
      return refNum
    } else {
      let stopInfo;
      if (item.deliveryInfos) {
        stopInfo = item.deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
      } else {
        stopInfo = item.dropInfo;
      }
      let refNum = "";
      if (stopInfo && stopInfo.refNums) refNum = stopInfo.refNums.join(", ");
      return refNum
    }
  }

  public getStreetAddressText(addr: AddressUS): string {
    if (!addr) return '';
    let street = addr.street;
    if (addr.street2) {
      street += `, ${addr.street2}`;
    }
    let txt = `${street}, ${addr.city}`;
    return txt;
  }

  public getStateAddressText(addr: AddressUS): string {
    if (!addr) return '';
    let txt = `${addr.state}`;
    if (addr.countryId && addr.countryId != Const.CountryId_USA) {
      let countryName = MasterData.getCountryNameById(addr.countryId);
      txt += ` (${countryName})`;
    }
    return txt;
  }

  getMoneyValue(value) {
    if ([undefined, null].includes(value)) return null;
    return InputHelper.formatMoney2(value);
  }

  isGenerateInvoice(item) {
    return item.isInvoicing
  }

  onGenerateInvoice(item) {
    if (!this.isGenerateInvoice) return;
    this.isLoading = true;
    const url = `v2/${Const.APIURI_ORDERS}/invoice/${item.id}`;
    this.api.POST(url).subscribe(
      resp => {
        this.isLoading = false;
        this.showSuccess(`File ${resp.data?.name} has been created!`)
        setTimeout(() => {
          this.getData()
        }, 300)
      }, err => {
        this.isLoading = false;
        this.showErr(err);
      }
    );
  }

  prepareParamGetList() {
    let params = super.prepareParamGetList();
    let { filter = '{}' } = params;
    filter = JSON.parse(filter);
    let action = this.activeAction;
    let queryParams = this.queryParams || {};
    if (queryParams.hasOwnProperty('action')) {
      action = queryParams.action || "";
      this.activeAction = action;
    }

    switch (action) {
      case "confirm":
        filter.podStatus = "not_confirmed";
        filter.invoiceStatus = "";
        break;

      case "generate":
        filter.podStatus = "confirmed";
        filter.invoiceStatus = "not_created";
        break;

      case "download":
        filter.podStatus = "confirmed";
        filter.invoiceStatus = "created";
        break;

      default:
        delete filter.podStatus;
        delete filter.invoiceStatus;
    }
    // console.log("prepareParamGetList", filter)
    params.filter = JSON.stringify(filter);
    return params;
  }

  setFilters(action: String, event) {
    if (action == this.activeAction) {
      this.activeAction = "";
    } else {
      this.activeAction = action;
    }
    // this.getData();
    this.routeWithQueryUrl({ action: this.activeAction, page: undefined })
  }

  isActionSelected(action) {
    return action == this.activeAction;
  }

  showConfirmAction() {
    if (!this.activeAction) return this.showErr("Please select a shipment status in the action bar.");
    let params = this.prepareParamGetList();
    let filter = params.filter;
    let isValid = false;
    if (filter) {
      filter = JSON.parse(filter);
      if (filter.clientId) isValid = true;
    }
    if (!isValid) return this.showErr("Please select a customer in the filter form.");

    switch (this.activeAction) {
      case "confirm":
        this.confirmYesNo('This action will confirm all PODs belonging to the orders in the list. Be careful when performing this action.', () => this.confirmTakingAction("confirm"))
        break;

      case "generate":
        this.confirmYesNo('This action will generate an invoice for all the orders in the list. Are you sure to take this action?', () => this.confirmTakingAction("generate"))
        break;

      case "download":
        this.confirmYesNo('This action will generate an invoice for all the orders in the list. Are you sure to take this action?', () => this.confirmTakingAction("download"))
        break;

      default:
    }
  }

  private opts = {
    confirm: {
      uri: "confirmPOD",
      msg: "All PODs have been confirmed."
    },
    generate: {
      uri: "generate",
      msg: "All orders have been generated invoice."
    },
    download: {
      uri: "download",
      method: "postExport",
      cb: (resp: any) => {
        // console.log("resp", resp);
        let filename = `invoices-${new Date().getTime()}.zip`;
        ApiService.handleDownloadResponse(resp, filename);
      }
    }
  }

  public isProcessing = false;
  confirmTakingAction(action: String) {
    this.isProcessing = true;
    let params = this.prepareParamGetList();
    this.batchActions(params, this.opts[String(action)]);
  }

  loadingRef;
  private batchActions(params: any, { uri, method = "POST", msg, cb }) {
    this.loadingRef = DialogService.openFormDialog1(LoadingComponent, {});
    let url = Const.APIV2(`${Const.APIURI_INVOICES_ORDERS}/${uri}`);
    this.api[method](url, {
      ...params
    }).subscribe(
      (resp) => {
        this.showSuccess(msg);
        setTimeout(() => {
          this.getData();
        }, 600);
        this.isProcessing = false;
        if (cb) cb(resp);
        this.loadingRef?.close();
      },
      (err) => {
        this.showErr(err);
        this.isProcessing = false;
        this.loadingRef?.close();
      }
    );
  }
  private getLastJobId(item) {
    let shipments = item.metadata?.shipments || [];
    if (shipments.length==0) return null;
    let shipment = shipments[0];
    let lastJobId = shipment.lastJobId
    if(!lastJobId){
      let jobIds = shipment?.jobIds
      if(jobIds&&jobIds.length>0) lastJobId = jobIds[jobIds.length-1];
    }
    return lastJobId;
  }

  goToDispatch(item){
    const lastJobId = this.getLastJobId(item);
    if(lastJobId){
      this.router.navigate([this.routeAdminDispatchList, lastJobId]);
    } else{
      this.router.navigate([this.routeAdminDispatchList],{queryParams:{search:item.shipmentId}});
    }
  }
}
