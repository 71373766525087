<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{headerText}}</div>
  </div>
</ng-template>
<div>
  <div #formItems form-order-items [model]="modelItems"></div>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>