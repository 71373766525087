import { Component, Input } from "@angular/core";
import { FormControl, Validators } from '@angular/forms';
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { VehicleType } from "@wearewarp/types/data-model";
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'edit-shipment-entry-equipment',
  templateUrl: './view.html',
  styleUrls: ['./index.scss']
})
export class EditShipmentEntryEquipment extends BaseFormDialog1 {

  public orderType;
  public batchShipmentType;
  vehicleType: VehicleType;
  public isDisableVehicleType = false;
  public msgDisableVehicleType = '';
  subClientSubscription: Subscription;
  @Input() modelOrder;
  @Input() onSave: (data) => Observable<any>;
  @Input() onRefreshDetailOrder: () => void;
  @Input() set myModel(value){
    let origin = value;
    let clientId= origin?.clientId;
    let subClientId;
    if(origin?.parentClientId){
      clientId = origin?.parentClientId;
      subClientId = origin?.clientId;
    }
    this.model = {...origin, clientId, subClientId};
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    vehicleType: {label: 'Type of Truck', required: true},
    // shipmentModeId: {label: 'Type of Truck', required: true}, // Type of Truck = Shipment Mode
    // equipmentId: {label: 'Equipment', required: true},
    clientId: {label: 'Customer', required: true},
    subClientId: {label: 'Sub Account', required: false},
    shipmentType: {label: 'Mode & Equipment:', required: true, submitReadOnly: true, initialValue: 'FTL'},
    isCrossDock: {label: 'Using Cross Dock', type: 'boolean', initialValue: false},
    isNotAutoRouteFTL: {label: 'Stop auto routing FTL', type: 'boolean', initialValue: false},
  }



  ngOnInit(): void {
    this.orderType = this.model?.type;
    if (this.model?.shipmentType != Const.ShipmentTypes.fullTruckLoad) {
      this.formGroupDeclaration.vehicleType.required = false;
    }
    this.vehicleType = this.model?.vehicleType;
    // Nếu đã có job thì ko cho update shipmentType
    // cho phép đổi từ FTL-> LTL. Nhưng ko cho đổi từ LTL->FTL khi đã có jobs
    let shipment = this.modelOrder?.metadata?.shipments?.[0];
    if (Utils.isArrayNotEmpty(shipment?.jobs) && this.model?.shipmentType != Const.ShipmentTypes.fullTruckLoad) {
      this.formGroupDeclaration.shipmentType.readOnly = true;
    }

    if (this.model?.shipmentType != Const.ShipmentTypes.fullTruckLoad) {
      if (Utils.isArrayNotEmpty(shipment?.legIds)) {
        //tạm thời remove vì legids bị remove rồi nhưng vẫn có trong shipment
        this.isDisableVehicleType = false;
        // this.msgDisableVehicleType = 'This shipment has been created legs. You can see the actual vehicle type in the dispatch screen.';
      } else if (Utils.isArrayNotEmpty(shipment?.jobs)) {
        const shipmentId = shipment.id;
        for (let shId of shipment.jobs[0]?.shipmentIds || []) {
          if (shId && shId != shipmentId) {
            this.isDisableVehicleType = true;
            this.msgDisableVehicleType = 'This shipment has been routed. You can see the actual vehicle type in the dispatch screen.';
            break;
          }
        }
      }
    }
    super.ngOnInit();

  }

  protected beforeBindModel(model) {
    super.beforeBindModel(model);
    return model;
  }

  protected afterBindModel(model): void {
    // this.onShipmentModeChange();
    // Nếu là Batch shipment thì không được sửa shipmentType (phải là Full Truck Load)
    let fc = this.formInput.get('shipmentType');
    if (this.orderType != Const.ShipmentEntryMode.single) {
      fc?.disable();
      this.formGroupDeclaration.shipmentType.readOnly = true;
    }
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  get isDisableChangeShipmentType() {
    return this.orderType != Const.ShipmentEntryMode.single;
  }

  get isFTL(): boolean {
    return this.getItemValue('shipmentType') == Const.ShipmentTypes.fullTruckLoad
  }

  selectedClient = null;
  selectedSubClient = null;
  listClients = [];
  listSubClients = [];
  listShipmentModes = MasterData.getAllShipmentModesV2();
  listEquipments = [];
  listShipmentTypes = [...Const.ShipmentTypesArray];

  public getShipmentTypeName(code: string): string {
    switch (code) {
      case Const.ShipmentTypes.fullTruckLoad: return 'FTL';
      case Const.ShipmentTypes.lessThanTruckload: return 'LTL';
      case Const.ShipmentTypes.parcel: return 'Parcel';
    }
  }

  getDropdownNoData(key: string): string {
    switch (key) {
      case 'equipmentId':
        let selectedShipmentModeId = this.formInput?.get('shipmentModeId')?.value;
        if (!selectedShipmentModeId) return 'Please select type of truck first';
        break;
      default:
        break;
    }
    return 'No data';
  }

  onDropdownSelectChange(key: string, event) {
    switch (key) {
      case 'clientId':
        this.onClientChange(event);
        break;
      case 'subClientId':
        this.onSubClientChange(event);
        break;
      case 'shipmentModeId':
        this.onShipmentModeChange(true);
        break;
      case 'shipmentType':
        this.onShipmentTypeChange(event);
      case 'vehicleType':
        this.vehicleType = event;
        if (event?.code) {
          this.setItemValue(key, event);
        } else {
          this.setItemValue(key, null);
        }
        break;
      default:
        break;
    }
  }

  get isLTL(): boolean {
    return this.getItemValue('shipmentType') == Const.ShipmentTypes.lessThanTruckload
  }

  get shouldShowShipmentMode(): boolean {
    return this.isFTL;
  }

  private onClientChange(clientId) {
    this.selectedClient = this.listClients.find(item => item.id === clientId);
    if(this.selectedClient?.isUseCrossDock && this.isLTL) this.setItemValue('isCrossDock', true)
    else this.setItemValue('isCrossDock', false)
    //clear old and get new sub-client data
    this.selectedSubClient = null;
    this.setItemValue('subClientId', null);
    this.listSubClients = null;
    if(this.selectedClient){
      this.fetchSubClient(this.selectedClient.id);
    }
  }

  private onShipmentTypeChange(value) {
    if(value === Const.ShipmentTypes.lessThanTruckload) {
      const clientId = this.getItemValue('clientId');
      let client = this.listClients.find(item => item.id === clientId);
      if(!this.model && client?.isUseCrossDock) this.setItemValue('isCrossDock', true)
      else this.setItemValue('isCrossDock', false)
    }
    else this.setItemValue('isCrossDock', false)
    let isVehicelRequired = value === Const.ShipmentTypes.fullTruckLoad ? true : false;
    this.formGroupDeclaration.vehicleType.required = isVehicelRequired;
    this.formGroupDeclaration.equipmentId.required = isVehicelRequired;
    for (let key of ['vehicleType']) {
      let fc = <FormControl>this.formInput.get(key);
      if (fc) {
        if (!isVehicelRequired) {
          fc.removeValidators(Validators.required);
        } else {
          fc.addValidators(Validators.required);
        }
        fc.updateValueAndValidity();
      }
    }
  }

  private onShipmentModeChange(clearEquipment = false) {
    let selectedShipmentModeId = this.formInput.get('shipmentModeId').value;
    let mode = this.listShipmentModes.filter(it => it.id == selectedShipmentModeId)[0];
    if (!mode) {
      return;
    }
    this.listEquipments = mode.equipments;
    if (clearEquipment) {
      this.setItemValue('equipmentId', null);
    }
  }

  getListForKey(key: string): Array<any> {
    switch (key) {
      case 'shipmentModeId': return this.listShipmentModes;
      case 'equipmentId': return this.listEquipments;
      default: return [];
    }
  }

  onBtnSave() {
    if (!this.needUpdate) return;
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    if (!data.vehicleType) {
      data.vehicleType = {};
    }
    if(data.subClientId){
      data.parentClientId = data.clientId;
      data.clientId = data.subClientId;
    }
    delete data.subClientId;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onRefreshDetailOrder();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

  private onSubClientChange(clientId) {
    // get selected sub-client data
    this.selectedSubClient = this.listSubClients.find(item => item.id === clientId);
  }
  firstTimeListUpdate = true;
  onListClientUpdate(clients: Array<any>) {
    let clientId = this.model.clientId;
    let subClientId = this.model.subClientId;
    if(this.firstTimeListUpdate&&clients.length>0){
      this.firstTimeListUpdate = false;
      setTimeout(()=>{
        this.selectedClient = clients.find(item => item.id === clientId);
        if(this.selectedClient){
         this.fetchSubClient(this.selectedClient.id, subClientId);
        }
      },0)
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.subClientSubscription?.unsubscribe();
  }

  private fetchSubClient(parentId: string,subClientId:string = null) {
    this.subClientSubscription?.unsubscribe();
    let url = Const.APIV2(`${Const.APIURI_CLIENTS}/${parentId}/sub-client`);
    this.subClientSubscription = this.api.GET(url).subscribe(
      resp => {
        this.listSubClients = resp.data.list_data;
        if (subClientId) {
          this.selectedSubClient = this.listSubClients.find(item => item.id === subClientId);
        }
      }
    );
  }

}
