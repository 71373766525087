import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { Mapbox } from "@services/mapbox";
import { BaseComponent } from "@abstract/BaseComponent";
import { Const } from "@const/Const";
import { Log } from "@services/log";
import { DeliveryInfo, LatLng } from "@wearewarp/types/data-model";
import polyline from "@mapbox/polyline";
import { ShipmentDetailService } from "@app/admin/shipment-entry/detail/shipment-detail-service";

@Component({
  selector: "[batch-map]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchMap extends BaseComponent {
  public mapBox: Mapbox;
  public isZoom: boolean = false;
  public dropOffStop: Array<[number, number]> = [];
  public pickUpStop: Array<[number, number]> = [];
  public otherStop: Array<[number, number]> = [];

  private _infos: DeliveryInfo[] = [];

  @Input() get infos(): DeliveryInfo[] {
    return this._infos;
  }
  set infos(value: DeliveryInfo[]) {
    let isEqual = true;
    if (value?.length != this?._infos?.length) {
      isEqual = false;
    } else if (value?.length && value?.length == this?._infos?.length) {
      for (let index = 0; index < value.length; index++) {
        const addr1 = value[index]?.addr || {};
        const addr2 = this?._infos[index]?.addr || {};
        if (typeof addr1 != typeof addr2) {
          isEqual = false;
        } else if (!this.objectsEqual(this.processLatLong(addr1), this.processLatLong(addr2))) {
          isEqual = false;
        }
      }
    }
    this._infos = value;
    if (!isEqual && value?.length > 1) {
      this.fetchRouteData();
    } else this.isLoadingRoute = false;
  }

  constructor(protected shipmentDetailService: ShipmentDetailService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.mapBox = new Mapbox();
    this.setupMap();
  }

  ngOnDestroy() {
    if (this.mapBox) {
      this.mapBox.remove();
      this.mapBox = null;
    }
  }

  get map() {
    return this.mapBox.getMapbox();
  }

  private setupMap() {
    this.mapBox.setupMap();
  }

  objectsEqual(o1, o2) {
    return JSON.stringify(o1) === JSON.stringify(o2) ? true : false;
  }

  processLatLong(address) {
    const latitude = address?.metadata?.latitude || address?.location?.latitude;
    const longitude = address?.metadata?.longitude || address?.location?.longitude;
    return { latitude, longitude };
  }

  processLocations(): LatLng[] {
    const locations: LatLng[] = [];
    this.pickUpStop = [];
    this.dropOffStop = [];
    if (this.infos?.length > 1) {
      for (let index = 0; index < this.infos.length; index++) {
        const info = this.infos[index];
        const { latitude, longitude } = this.processLatLong(info?.addr);
        if (latitude && longitude) {
          locations.push({
            latitude,
            longitude,
          });
          if (info.type == Const.TaskType.PICKUP) {
            this.pickUpStop.push([longitude, latitude]);
          }
          if (info.type == Const.TaskType.DROPOFF) {
            this.dropOffStop.push([longitude, latitude]);
          }
          if (info.type != Const.TaskType.PICKUP && info.type != Const.TaskType.DROPOFF) {
            this.otherStop.push([longitude, latitude]);
          }
        }
      }
    }
    return locations;
  }
  routeData: any;
  showDataOnTheMap(resp) {
    this.routeData = resp?.data;
    if (this.routeData) {
      let line = this.routeData.line ? polyline.decode(this.routeData.line) : this.routeData.points;
      let route = line.map((it) => [it[1], it[0]]);
      const routes = [];
      if (route.length > 0) {
        routes.push(route);
      }
      if (routes.length > 0) {
        this.mapBox.clearMap();
        setTimeout(() => {
          if (this.isZoom) {
            this.mapBox.addRoute(routes, false);
          } else {
            this.isZoom = true;
            this.mapBox.addRoute(routes, true);
          }
          Log.d("pickUpStop", this.pickUpStop);
          Log.d("dropOffStop", this.dropOffStop);

          this.mapBox.addPoints({ points: this.pickUpStop, color: Const.MAP_LOCATION_COLOR_PICK, des: "pickup" });
          this.mapBox.addPoints({ points: this.dropOffStop, color: Const.MAP_LOCATION_COLOR_DROP, des: "dropoff" });
          this.mapBox.addPoints({ points: this.otherStop, color: Const.MAP_LOCATION_COLOR_DEFAULT, des: "other" });
          this.isLoadingRoute = false;
        }, 200);
      }
    }
  }
  // api route-data trên server đang để branch: feature/route-data-with-location-list
  fetchRouteData() {
    this.isLoadingRoute = true;
    const locations: LatLng[] = this.processLocations();
    Log.d("locations ", locations);
    if (locations?.length > 1) {
      let url = `${Const.APIURI_ORDERS_V5}/route-data`;
      this.api.POST(url, { locations }).subscribe(
        (resp) => {
          Log.d("fetchRouteData done ", resp);
          if (resp?.data?.cost?.distance || resp?.data?.cost?.time) {
            this.shipmentDetailService.changeRouteCostData(resp.data.cost);
          }
          this.showDataOnTheMap(resp);
        },
        (err) => {
          this.isLoadingRoute = false;
          this.showErr(err);
        }
      );
    } else this.isLoadingRoute = false;
  }
  isLoadingRoute: boolean = true;

  isVisibleModal: boolean = false;
  showBigMap() {
    if (!this.isLoadingRoute) this.isVisibleModal = true;
  }
  visibleModalChange() {
    this.isVisibleModal = false;
  }
}
