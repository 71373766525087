import { BaseComponent } from "@abstract/BaseComponent";
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import DeliveryNetwork from "./network";
import { ulid } from 'ulid'
import { Const } from "@const/Const";
import _ from 'underscore'
import { Utils } from "@services/utils";
import { DialogService } from "@dialogs/dialog.service";
import { ManualRoute } from "../manual-route";
import { PlanningRouteDialog } from "@app/admin/planning/dialog";
import { UpdateShipmentReviewStatusForm } from "@app/admin/planning/update-review-status";
import { EventsContainer } from "@app/admin/components/events";
import { getFeatureFlags } from "@services/feature-flag.service";
import { InitReturnLeg } from "./init-return-leg/init-return-leg";
import { DataorchService } from "@services/dataorch.service";


@Component({
    selector: '[shipment-structure]',
    templateUrl: './index.html',
    styleUrls: ['./styles.scss']
})
export class ShipmentStructure extends BaseComponent {
    @Input() items
    @Input() compact
    @Input() viewOnly
    @Input() presentedShipment = null;
    @Input() isShowActualTime: boolean = false;
    dataorch: DataorchService

    @Output() refreshDetailOrder: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('structure', { static: true }) svgRef: ElementRef;
    
    _shipment: any = null;
    @Input() set shipment(value) {
        this._shipment = value;
        this.network?.load(this.shipment, this.children)
        this.network?.redraw()

    }
    get shipment() {
        return this._shipment
    }

    _children: any = null;
    @Input() set children(value) {
        this._children = value
        this.network?.reset()
        this.network?.load(this.shipment, this.children)
        this.network?.redraw()
    }
    get children() {
        return this._children
    }

    @Input() highlights: any[] = []
    @Output() onSelectShipment: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSelectContextMenu: EventEmitter<any> = new EventEmitter<any>();
    @Output() onHover: EventEmitter<any> = new EventEmitter<any>();
    network: DeliveryNetwork = null
    showPopUp: boolean = false
    popUpLocation = [130, 40]
    popupData: any = null
    hoverData: any = null
    // presentedShipment: any = null
    warehouses = null
    newOutboundData = null
    editMode: boolean = true
    showEvents: boolean = false
    @Input() refId: string = 'struture'

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }

    ngOnInit(): void {
        this.onClick = this.onClick.bind(this)
        this.onMouseOver = this.onMouseOver.bind(this)
        this.onMouseOut = this.onMouseOut.bind(this)
        this.onSelectShipmentMenu = this.onSelectShipmentMenu.bind(this)
        this.onRouteCreated = this.onRouteCreated.bind(this)

        this.network = new DeliveryNetwork()
        this.network.load(this.shipment, this.children)
        this.network.click = this.onClick
        this.network.mouseOut = this.onMouseOut
        this.network.mouseOver = this.onMouseOver
        this.network.highlight(this.highlights)
        // this.network.drawTo(this.refId)
        getFeatureFlags().isFlagSetNonBlocking('SHOW_EVENT').subscribe((res) => {
            if (res) {
                this.showEvents = true;
            }
        })
      
    }


    ngAfterViewInit() {
        this.network.drawTo(`#${this.refId}`)
        // child is set
    }

    ngOnChanges(): void {
        if (!this.network) {
            this.network = new DeliveryNetwork()
            this.network.click = this.onClick
            this.network.mouseOut = this.onMouseOut
            this.network.mouseOver = this.onMouseOver
            this.network.load(this.shipment, this.children)
            // this.network.drawTo('#structure')        
        } else {
            this.network.load(this.shipment, this.children)
            this.network.highlight(this.highlights)
            this.network.redraw()
        }
        this.loadClientMetadata()
    }

    onClick(event) {
        // toggle
        if (event.id === this.popupData?.id) {
            this.showPopUp = false
            this.popupData = null
            return
        }
        this.showPopUp = true
        this.popupData = event
        this.popupData.viewOnly = this.viewOnly
        const { coords } = event
        this.popUpLocation = [
            Math.max(0, coords[0][0] + coords[1][0] / 2 - 100),
            coords[0][1] + coords[1][1] + 10
        ]

        if (this.viewOnly) return

        this.editMode = true;
        const { shipment } = event
        if (shipment) {
            this.showPopUp = false
            this.onSelectShipment?.emit(shipment._id)
            this.presentedShipment = shipment;
            if (this.presentedShipment.status === Const.OrderStatus.removed) {
              this.editMode = false;
            }
        }
    }

    onClosePopup() {
        this.showPopUp = false
        this.popupData = null
    }

    onCloseShipment() {
        this.closeShipment()
        this.popupData = null
        this.network?.redraw()
    }

    closeShipment() {
        this.presentedShipment = null
        this.confirmingRemoveLeg = false
        this.removingLeg = false
        this.network?.highlight([])
        this.onSelectShipment?.emit(null)
    }

    onMouseOver(event) {
        this.hoverData = event
        const { coords } = event
        const c = [
            Math.max(0, coords[0][0] + coords[1][0] / 2 - 100),
            coords[0][1] + coords[1][1] + 10
        ]
        this.hoverData['coords'] = c
        this.onHover?.emit(this.hoverData)
    }

    onMouseOut(event) {
        this.hoverData = null
        this.onHover?.emit(null)
    }

    onSelectLocationMenu(action) {
        const { location } = this.popupData
        if (!location) {
            this.onClosePopup()
            return
        }
        if (action === 'NEW_OUTBOUND') {
            this.onTriggerNewOutbound(location)
        }
        if (action === 'INITIALIZE_RETURN') {
          this.onTriggerInitializeReturn(location)
        }
        this.onClosePopup()
    }

    onTriggerNewOutbound(location) {
        this.closeShipment()
        this.newOutboundData = {
            from: location
        }
    }
    onTriggerInitializeReturn(location) {
      DialogService.openDialog(InitReturnLeg, {
        nzComponentParams: {
          from: location,
          parent: this.shipment,
          onSave: data => this.addReturnLeg(data),
          onSubmitSucceeded: resp => {
            const created = resp.data.list_data[0];
            this.onNewLegCreated(created)
          }
        },
        nzClassName: "modal-task-return",
        nzCentered: true,
        nzTitle: null,
        nzClosable: false,
      });
    }
    onCancelCreateNewLeg() {
        this.newOutboundData = null
    }
    onNewLegCreated(created) {
        if (created) {
            this.children.push(created)
            this.network.load(this.shipment, this.children)
            this.network.highlight([created.id])
            this.network.redraw()

            this.onSelectShipment?.emit(created._id)
            this.presentedShipment = created
            this.editMode = true
            this.showInfo(`New LEG was created successfully: ${created.warpId}`)
        }
        this.newOutboundData = null
    }

    onSelectShipmentMenu(action) {
        const { shipment } = this.popupData
        if (!shipment) {
            this.onClosePopup()
            return
        }

        if (action === 'SHOW_DETAIL') {
            this.closeShipment()
            this.newOutboundData = null
        }
        if (action === 'DUPLICATE') {
            this.duplicateLeg(shipment)
        }
        this.onClosePopup()
    }

    public duplicatingLeg: boolean = false
    duplicateLeg(shipment) {
        // return alert(`Feature is being upgraded, it's back soon.`);
        const duplicated: any = {
            deliveryInfos: shipment.deliveryInfos.map(it => Object.assign({}, it)),
            parentId: shipment.parentId,
            id: ulid(),
            clientId: shipment.clientId,
            shipmentType: shipment.shipmentType,
            isCrossDock: shipment.isCrossDock,
            shipmentModeId: shipment.shipmentModeId,
            equipmentId: shipment.equipmentId,
            tempRange: shipment.tempRange,
            shipmentTransitType: shipment.shipmentTransitType,
            itemIds: shipment.itemIds,
            name: '...'
        }
        for (let info of duplicated.deliveryInfos) {
            info.windows = []
            info.id = ulid()
        }

        this.children.push(duplicated)
        this.network.load(this.shipment, this.children)
        this.network.redraw()

        const url = Const.APIV2('shipments/add_sub_shipment')
        this.duplicatingLeg = true
        this.api
            .POST(url, {
                parentId: shipment.parentId,
                shipments: [duplicated]
            })
            .subscribe(
                (resp) => {
                    this.duplicatingLeg = false
                    const created = resp.data.list_data[0]
                    this.children = this.children.filter(it => it.id !== duplicated.id)
                    this.onNewLegCreated(created)
                    // this.children = this.children.concat(created)
                    // this.network.load(this.shipment, this.children)
                    // this.network.redraw()
                    // this.showInfo(`New LEG was created successfully: ${created[0].warpId}`)
                },
                (err) => {
                    this.duplicatingLeg = false
                    this.showErr(`Error while duplicating LEG ${shipment.warpId}: ${JSON.stringify(err)}`)
                }
            )
    }

    addReturnLeg(data) {
      const url = Const.APIV2('shipments/add_return_leg')
      return this.api.POST(url, {
        parentId: this.shipment.id,
        shipment: data.shipment,
        returnReason: data.returnReason
      });
    }

    confirmRemovingLeg() {
        this.confirmingRemoveLeg = true
    }

    cancelRemovingLeg() {
        this.confirmingRemoveLeg = false
    }


    public confirmingRemoveLeg: boolean = false
    public removingLeg: boolean = false
    removeLeg(shipment) {
        const url = Const.APIV2(`shipments/${shipment.id}`)
        this.removingLeg = true
        this.api.DELETE(url).subscribe((res) => {
            this.removingLeg = false
            if (res.message === 'Success') {
                this.popupData = null;
                this.confirmingRemoveLeg = false;
                this.onDeliveryUpdated();
            } else {
                this.showErr(`Error while deleting shipment leg!`)
            }
        }, (err) => {
            this.removingLeg = false
            this.showErr(`Error while deleting shipment leg. ${err}`)
        })
    }

    canDelete(shipment) {
        if (!shipment.parentId) return false
        if (shipment.lastJobId) return false
        if (shipment.externalId) return false
        if (shipment.status === Const.OrderStatus.removed) return false;
        return true
    }

    canCreateSingleRoute(shipment) {
      if (shipment.lastJobId) return false;
      if (shipment.status === Const.OrderStatus.removed) return false;
      return true;
    }

    toggleEdit() {
        this.editMode = !this.editMode
    }

    public refreshingShipment: boolean = false
    onDeliveryUpdated() {
        if (!this.presentedShipment) return
        // refresh data
        const urls = [];
        if(this.presentedShipment.id) urls.push(Const.APIV2(`shipments/${this.presentedShipment.id}`));
        if(this.presentedShipment.parentId) urls.push(Const.APIV2(`shipments/${this.presentedShipment.parentId}`));
        if(!urls.length) return

        this.refreshingShipment = true
        this.api.concurrentGET(urls).subscribe((res) => {
            const resChild = res[0];
            const resParent = res[1];
            this.refreshingShipment = false
            if (resChild.message === 'Success') {
                this.presentedShipment = resChild.data
                this.children = this.children.map(it => it.id === this.presentedShipment.id ? resChild.data : it)
                this.shipment = resParent?.message === 'Success' ? resParent.data : resChild.data;
                this.network.reset()
                this.network.load(this.shipment, this.children)
                this.network.redraw()
                this.refreshDetailOrder.emit()
            }
        }, (err) => {
            this.refreshingShipment = false
            // this.showErr(`Error while refreshing shipment ${this.presentedShipment.warpId}`)
            this.showErr(err);
        })
    }

    onRouteCreated(route) {
        this.presentedShipment.lastJobId = route.id
        this.showInfo(`Route ${route.label} has been created`)
    }

    onBtnManualRouteShipment() {
        if (!this.presentedShipment) return
        if (this.presentedShipment.lastJobId) return
        // if ((data.shipmentIds || []).length === 1) {
        //   data = data?.metadata?.shipments[0];
        // }
        DialogService.openDialog(PlanningRouteDialog, {
          nzComponentParams: {
            shipments: [this.presentedShipment],
            onRouteCreated: this.onRouteCreated
          },
          nzWidth: 848,   // content 800px + padding left, right 24px
          nzClassName: 'modal-no-padding',
          nzCentered: true,
        })
    }

    clientMeatadata: any = {}
    loadClientMetadata() {
        if (this.clientMeatadata?.clientId === this.shipment?.clientId) return
        this.clientMeatadata = {
            clientId: this.shipment?.clientId
        }
        if (!this.shipment?.clientId) return
        const url = Const.APIV2(`${Const.APIURI_METADATA}/CUSTOMER_${this.shipment.clientId}/PLANNING`)
        this.api.GET(url).subscribe((res) => {
            this.clientMeatadata = res
            this.clientMeatadata.clientId = this.shipment.clientId
        })
    }

    updateShipmentReviewStatus(shipment) {
        this.modalService.create({
            nzTitle: `Update Review Status for shipment ${shipment.warpId}`,
            nzContent: UpdateShipmentReviewStatusForm,
            nzWidth: "800px",
            nzComponentParams: {
                id: shipment.id,
                warpId: shipment.warpId,
                status: shipment.review?.status || {}
            },
            nzOnOk: (comp) => {
                comp.onSave().subscribe((res) => {
                    const { review } = res.data
                    shipment.review = review
                }, (err) => {
                    this.showErr(err)
                })
            },
            nzOnCancel: (comp) => {
                comp.onCancel()
            }
        })
    }

    showShipmentHistory(presentedShipment) {
        this.drawerService.create({
            nzContent: EventsContainer,
            nzClosable: false,
            // nzWidth: "90%",
            nzBodyStyle: {
                // padding: "0"
            },
            nzContentParams: {
                type: 'SHIPMENT',
                id: presentedShipment.id
            }
        });
    }
}
