import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Const } from '@const/Const';
import { FormDataShipmentLocation } from '@wearewarp/types/rest-api/admin/form-data/shipment-entry';
import { DateUtil } from '@services/date-utils';
import { MasterData } from '@services/master.data';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { Utils } from '@services/utils';
@Component({
  selector: 'drag-and-drop-locations',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class DragAndDropLocations {
  private _locations: Array<(FormDataShipmentLocation)> = [];
  @Input() get locations() { return this._locations };
  set locations(value) {
    this._locations = value;
    //chuẩn hóa trường dữ liệu
    this._locations = this.normalizeLocations(value)
    //sắp xếp thứ tự hiển thị theo trường "sort" (thứ tự 1,2,3,4...)
    this._locations = this.sortLocations(this._locations) 
  } //list locations
  @Input() batchShipmentType: string;
  @Output() onChange = new EventEmitter;

  normalizeLocations(locations){
    return locations?.map((location, index) => {
      return {
        ...location,
        shipmentId: location?.shipmentId || 0, //trường hợp không có shipmentId => set=0 để group
        sort: index + 1, //input data ban đầu trả theo thứ tự nào thì tạo "sort" theo thứ tự đó,
        index: this.getLocationIndex(index)
      }
    })
  }

  sortLocations(locations){
    return locations?.sort((a, b) => {
      let fa = a?.sort,
          fb = b?.sort;
  
      if (fa < fb) {
          return -1;
      }
      if (fa > fb) {
          return 1;
      }
      return 0;
    });
  }

  getLocationId(location){
    return location?.id
  }

  getLocationIndex(index){
    return index
  }

  getLocationName(info: FormDataShipmentLocation) {
    return info?.locationName;
  }

  public getLocationAddress(info: FormDataShipmentLocation): string {
    return MasterData.getAddressText(info?.addr);
  }

  getRefNum(info: FormDataShipmentLocation) {
    return info?.refNums?.join("; ");
  }

  getAccessCode(info: FormDataShipmentLocation) {
    return info?.accessCode;
  }

  hasTimePeriods(info: FormDataShipmentLocation): boolean {
    return info?.windows != null && info?.windows?.length != 0;
  }
  getTimePeriods(info: FormDataShipmentLocation) {
    let windows =  info?.windows
      ?.map((window) =>
        DateUtil.displayTimeWindow(window, {
          timezone: info?.addr?.metadata?.timeZoneStandard,
          format: "MM/DD/YYYY h:mm A",
        })
      );
    if (Utils.isArrayNotEmpty(windows)) return windows.join("\n");
    else if (info.requiresAppointment) return 'Requires Appointment.';
    else return '';
  }
  hasServiceOptions(info: FormDataShipmentLocation): boolean {
    return !!(info?.serviceOptions?.length);
  }
  getServiceOptions(info: FormDataShipmentLocation) {
    return info?.serviceOptions?.join(", ");
  }

  getLocatioName(location){
    return location?.locationName || this.naText
  }

  getSortValue(location){
    return location?.sort
  }

  isPickupLocation(location){
    return location?.type == Const.TaskType.PICKUP
  }

  isDropoffLocation(location){
    return location?.type == Const.TaskType.DROPOFF
  }

  getItemText(location){
    const count = location?.items?.length || 0;
    const subText = count > 1 ? 'items' : 'item' 
    return `${count} ${subText}`
  }

  getShipmentIndex(location) {
    if (this.batchShipmentType == Const.ShipmentEntryMode.multiPickDrop && location.shipmentId) {
      let shipmentWarpId = location.shipmentWarpId || location.shipmentId;
      return `- Shipment ${shipmentWarpId}`;
    } 
    return '';
  }

  isDraggable(location){
    const type = location?.type
    let isDraggable = true
    switch (this.batchShipmentType) {
      case Const.ShipmentEntryMode.multiDrop:
        if (type == Const.TaskType.PICKUP) {
          isDraggable = false
          this.removeCursor(location)
        }
        break;
      case Const.ShipmentEntryMode.multiPick:
        if (type == Const.TaskType.DROPOFF) {
          isDraggable = false
          this.removeCursor(location)
        }
        break;
      default:
        break;
    }
    return isDraggable
  }

  removeCursor(location){
    const element = document.getElementById(location?.id)
    element?.classList?.remove(this.cursorDragAndDropClassName)
  }

  groupBy(objectArray, property) {
    return objectArray?.reduce(function (accumulator, obj) {
      let key = obj[property]
      if (!accumulator[key]) {
        accumulator[key] = []
      }
      accumulator[key].push(obj)
      return accumulator
    }, [])
  }

  isValidLocationSort(locations){
    // Trong cùng 1 shipmentId => "sort" của PICKUP luôn phải lớn hơn "sort" của DROPOFF
    let invalidPickAndDrops = []
    const groupBy = 'shipmentId'
    const groupLocations = this.groupBy(locations, groupBy) //nhóm các cặp Pickup-Dropoff theo shipmentId để so sánh
    for (let shipmentId in groupLocations) {
      let pickAndDrop = groupLocations[shipmentId]
      let pickupSort, dropoffSort
      pickAndDrop?.forEach((item, index) => {
        if (item?.type == Const.TaskType.PICKUP) pickupSort = index
        if (item?.type == Const.TaskType.DROPOFF) dropoffSort = index
        if (pickupSort > dropoffSort) invalidPickAndDrops.push(pickAndDrop)
      })
    }
    if (invalidPickAndDrops.length) return false
    return true
  }
  
  drop(e: CdkDragDrop<string[]>) {    
    let draftLocations = [...this.locations]
    moveItemInArray(draftLocations, e.previousIndex, e.currentIndex);
    if (this.isValidLocationSort(draftLocations)){
      //update this.locations => render lại view
      this.locations = this.normalizeLocations(draftLocations)
      //trả dữ liệu mới ra ngoài
      this.onChange.emit(this.locations);
    }
  }
  
  private get naText(){
    return "N/A"
  }

  public get dragAndDropClassName(){
    return 'locationDragAndDrop'
  }

  public get cursorDragAndDropClassName(){
    return 'cursorDragAndDrop' //class này để hiển thị style con trỏ
  }

  public get getIcon(){
    return {
      dragIcon: "assets/img/drag_icon.svg",
      windowTimesIcon: "assets/img/windows_time_icon.svg",
      serviceOptionsIcon: "assets/img/service_options_icon.svg"
    }
  }
}
