import { Component, Input, ViewChild } from "@angular/core";
import { FormDataSingleShipmentRevenue } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";
import { BaseForm } from "../../../../base/form-base";
import { FormInputCostV3 } from "../input-cost-v3";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { InputHelper } from "@services/input-helper";
import { ApiService } from "@services/api.service";

@Component({
  selector: '[form-single-shipment-revenue]',
  templateUrl: './view.html',
  styleUrls: []
})
export class FormSingleShipmentRevenue extends BaseForm<FormDataSingleShipmentRevenue> {

  @ViewChild('costForm') costForm: FormInputCostV3;

  @Input() modelDraft;
  @Input() selectLocations: [];

  constructor(protected api: ApiService) {
    super();
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    settings: {label: '', type: 'formGroup', notAcceptEmpty: true, childItem: {
      isDoNotInvoice: {label: 'Do not invoice', type: 'boolean', notAcceptEmpty: true}
    }},
    cost: {label: '', type: 'formGroup', childItem: FormInputCostV3.declarationForCreate}
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    setTimeout(() => this.updateServiceOption_FromDeliveryinfo(), 1);
  }

  protected onModelDataChanged(): void {
    if (!this.modelDraft) return;
    this.updateServiceOption_FromDeliveryinfo();
  }

  join(...args): string {
    return args.join(".");
  }

  serviceOptionsForCostSection = [
    {label: 'Additional services', items: MasterData.ShipmentServiceOptionsAddition},
    {label: 'Pickup services', items: MasterData.ShipmentServiceOptionsPickup},
    {label: 'Delivery services', items: MasterData.ShipmentServiceOptionsDelivery},
  ];

  protected updateServiceOption_FromDeliveryinfo() {
    let pickInfo = this.modelDraft?.pickInfo;
    let dropInfo = this.modelDraft?.dropInfo;
    if (!pickInfo || !dropInfo) return;
    let serviceOptionsPickup = pickInfo?.serviceOptions || [];
    let serviceOptionsDropoff = dropInfo?.serviceOptions || [];
    let currentOptions = this.costForm?.getValueServiceOptions() || [];
    this.updateServiceOptionAfterGetItemValue(currentOptions);
    let arr = [];
    for (let item of currentOptions) {
      let option = MasterData.getServiceOptionById(item._id);
      if (!option) continue;
      if (option.type == 'addition') {
        arr.push(item);
      } else if (option.type == 'pickup') {
        for (let i=0; i < serviceOptionsPickup.length; i++) {
          if (serviceOptionsPickup[i] == item._id) arr.push(item);
        }
      } else if (option.type == 'delivery') {
        for (let i=0; i < serviceOptionsDropoff.length; i++) {
          if (serviceOptionsDropoff[i] == item._id) arr.push(item);
        }
      }
    }
    for (let itemId of serviceOptionsPickup) {
      if (arr.filter(it => it._id == itemId).length == 0) {
        arr.push({_id: itemId, deliveryId: pickInfo.id});
      }
    }
    for (let itemId of serviceOptionsDropoff) {
      if (arr.filter(it => it._id == itemId).length == 0) {
        arr.push({_id: itemId, deliveryId: dropInfo.id});
      }
    }
    // format money value to number VD: $10 -> 10
    for (let item of arr) {
      item.rate = InputHelper.getValueMoney(item.rate);
      item.total = InputHelper.getValueMoney(item.total);
    }

    this.costForm?.setValueServiceOptions(arr);
  }

  updateServiceOptionAfterGetItemValue(serviceOptions) {
    if (!Utils.isArrayNotEmpty(serviceOptions)) return;
    for (let item of serviceOptions) {
      item.rate = InputHelper.getValueMoney(item.rate);
      if (Utils.isNumber(item?.qty) && Utils.isNumber(item?.rate) && item.qty > 0 && item.rate > 0) {
        item.total = item.qty * item.rate;
      }
    }
  }
  
}