<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Edit Customer & Equipment</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <div class="mw392 flex1" *ngFor="let key of ['shipmentType']">
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <nz-form-item>
            <nz-form-control>
                <nz-radio-group nzBackdrop="true" nzButtonStyle="solid"
                (ngModelChange)="onDropdownSelectChange(key, $event)"
                [formControlName]="key" >
                <label nz-radio-button *ngFor="let item of listShipmentTypes"
                    [nzValue]="item" [nzDisabled]="isDisableChangeShipmentType">{{getShipmentTypeName(item)}}</label>
            </nz-radio-group>
            </nz-form-control>
        </nz-form-item>
    </div>
    <div>
      <div class="mw392" *ngFor="let key of ['vehicleType']">
          <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <nz-form-item class="right16">
              <!-- <nz-form-control>
                  <nz-select nzBackdrop="true" [formControlName]="key"
                  [nzNotFoundContent]="getDropdownNoData(key)"
                  (ngModelChange)="onDropdownSelectChange(key, $event)">
                      <nz-option *ngFor="let item of getListForKey(key)"
                          [nzValue]="item.id" [nzLabel]="item.name">
                      </nz-option>
                  </nz-select>
              </nz-form-control> -->
              <div [value]="vehicleType" style="min-width: 300px;" vehicle-selector (valueChange)="onDropdownSelectChange(key, $event)" [isDisabled]="isDisableVehicleType" [quoting]="false" [withOptions]="true"></div>
              <div *ngIf="isDisableVehicleType" class="warning f13">{{msgDisableVehicleType}}</div>
          </nz-form-item>
      </div>
    </div>
    <div *ngFor="let key of ['clientId']" class="mw392">
      <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <nz-form-item class="right16">
          <nz-form-control>
              <select-by-searching [formControlName]="key"
              [version]="2"
              [(listData)]="listClients"
              (listDataChange)="onListClientUpdate($event)"
              [placeholder]="getPlaceHolder(key)"
              (ngModelChange)="onDropdownSelectChange(key, $event)">
              </select-by-searching>
          </nz-form-control>
      </nz-form-item>
    </div>
    <ng-container *ngIf="listSubClients&&listSubClients.length>0">
        <div *ngFor="let key of ['subClientId']" class="mw392">
            <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
            <nz-form-item class="right16">
                <nz-form-control>
                    <nz-select (ngModelChange)="onDropdownSelectChange(key,$event)"
                               [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" [nzShowSearch]="true" nzAllowClear>
                        <nz-option *ngFor="let subClient of listSubClients" [nzLabel]="subClient.name"
                                   [nzValue]="subClient.id">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
    </ng-container>

    <ng-container *ngIf="isLTL">
        <div *ngFor="let key of ['isCrossDock']">
            <nz-form-item>
                <nz-form-control>
                    <div nz-checkbox [formControlName]="key">{{getLabel(key)}}
                    </div>
                </nz-form-control>
            </nz-form-item>
        </div>
    </ng-container>
    <ng-container *ngIf="isFTL">
        <div *ngFor="let key of ['isNotAutoRouteFTL']">
            <nz-form-item>
                <nz-form-control>
                    <div nz-checkbox [formControlName]="key">{{getLabel(key)}}
                    </div>
                </nz-form-control>
            </nz-form-item>
        </div>
    </ng-container>
  </form>
</div>
<div form-footer class="no-border"
    [canClickOK]="needUpdate"
    [canClickCancel]="true"
    [onProgress]="onProgress"
    [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
    (onCancel)="closeDialog()">
  </div>
