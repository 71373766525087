import { Component } from '@angular/core';
import { Const } from '@const/Const';
import { BaseList } from '../base/list';
import { KeyLang } from '@locale/generated/keys';
import { DialogService } from '@app/dialogs/dialog.service';
import { InputHelper } from '@services/input-helper';
import { PermissionCode } from '@const/PermissionCode';

@Component({
  selector: 'user-list',
  templateUrl: './list.html',
  styleUrls: ['../list.scss', './style.scss']
})
export class UserListComponent extends BaseList {
  txtLastLogin = '';
  txtFullName = '';
  txtRole = '';
  txtImage = '';
  txtLastActivities = ''

  public get topNavOps(): TopNavOptions {
    return {
      localizeKey: KeyLang.Txt_ListAccount,
      title: '',
      refreshFn: this.onBtnRefresh.bind(this),
      buttons: [
        { localizeKey: KeyLang.Txt_NewAccount, title: '', nzIcon: 'plus-circle', action: this.onBtnAdd.bind(this) }
      ]
    };
  }

  constructor(private dlgService: DialogService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  setupLanguage() {
    super.setupLanguage();
    this.txtHintSearch = this.text(KeyLang.Txt_PlaceholderSearchUsers);
    this.txtLastLogin = this.text(KeyLang.Txt_LastLogin);
    this.txtFullName = this.text(KeyLang.Txt_FullName);
    this.txtRole = this.text(KeyLang.Txt_Role);
    this.txtImage = this.text(KeyLang.Txt_Image);
    this.txtLastActivities = this.text(KeyLang.Txt_Profile_lastActivities);
  }

  protected getApiUrl(): string {
    return Const.APIURI_USERS;
  }

  protected getPageTitle(): string {
    return 'List users';
  }


  getWarehouseLink(warehouse) {
    return `${this.routeAdminLocationList}?search=${warehouse.name}`;
  }

  onBtnResetPw(user) {
    this.dlgService.changePwd(user);
  }

  canDeleteUser(user) {
    return this.requirePermissions([PermissionCode.user.delete]);
  }

  protected getItemName(user): string {
    return this.getFullName(user);
  }

  getRouterLink(user) {
    return [this.routeAdminUserList, user.id];
  }

  shouldShowClientForRole(user) {
    return this.requirePermissions([PermissionCode.client.read]) && user.client;
  }

  shouldShowCarrierForRole(user) {
    return this.requirePermissions([PermissionCode.carrier.read]) && user.carrier;
  }

  shouldShowOrganizationForRole(user) {
    return user?.organization;
  }

  isBlocked(user) {
    if (!user?.failedAttempt) return false;
    const {history, ...attempt} = user.failedAttempt;
    for (let key of Object.keys(attempt)) {
      if (attempt[key]?.maxExceededMsg) {
        return true;
      }
    }
    return false;
  }

  private getBlockReason(user): string[] {
    const {history, ...attempt} = user.failedAttempt;
    const arr: string[] = [];
    for (let key of Object.keys(attempt)) {
      if (!attempt[key]?.maxExceededMsg) {
        continue;
      }
      switch (key) {
        case 'login':
        case 'login-less-password':
          arr.push('login failures');
          break;
        case 'forgot_pw':
          arr.push('reset password failures');
          break;
      }
    }
    return arr;
  }

  onBtnUnBlockUser(user) {
    if (this.isAdminReadOnlyRole) return;
    let reason: string = this.getBlockReason(user).join(', ');
    let msg = `The account <b>${user.email}</b> seemed to be blocked due to ${reason} too many times. Would you like to unblock it?`;
    this.confirmYesNo(msg, () => {
      this.auth.resetFailedAttempt(user.id).subscribe(
        resp => {
          this.onBtnRefresh();
        }, err => {
          this.showErr(err);
        }
      )
    });
  }

  shouldShowWarehouseForRole(user) {
    return this.requirePermissions([PermissionCode.warehouse.read]) && user.warehouses?.length;
  }

  formatPhone(phone) {
    if(!phone) return 'N/A';
    return InputHelper.formatPhone(phone);
  }

  displayLastLoginTime(item: any): string {
    return item?.dt_last_login ? this.displayDateTime(item?.dt_last_login, Const.DATETIME_FORMAT_FROM_DB) : 'N/A';
  }
}
